import { Button, Input, Stack, Tooltip } from '@alexpireddu/map-ui';
import styled from '@emotion/styled';
import { FC } from 'react';
import { CitySiteNewsItem } from '../../apis/newsApis';
import { colors } from '../../theming/theme';
import Timer from './Timer';
import _ from 'lodash';

export interface NewsRowProps {
  newsItem?: CitySiteNewsItem;
  onEdit: () => void;
  disabled: boolean;
}

const FloatingContainer = styled.div({
  position: 'relative',
  width: '100%',
});

const FloatingInput = styled(Input)({
  paddingRight: 85,
  backgroundColor: colors.grey200,
  color: colors.text100,
});

const FloatingStack = styled(Stack)({
  position: 'absolute',
  right: 2,
  top: 1,
  height: '100%',
});

const NewsRow: FC<NewsRowProps> = ({ newsItem, onEdit, disabled }) => (
  <Stack spacing={1} direction="row">
    <FloatingContainer>
      <FloatingInput
        isFullWidth
        readOnly
        value={newsItem?.text}
        size="md"
        placeholder="Inserisci qui una nuova news"
        onClick={() => {
          if (_.isNil(newsItem)) {
            onEdit();
          }
        }}
      />
      <FloatingStack spacing={1} direction="row">
        <Timer expiration={newsItem?.expiration!} />
        {disabled ? (
          <Tooltip content={<span>Il comune non è attivo</span>}>
            <Button
              variant="unstyled"
              size="sm"
              icon="pen"
              disabled={disabled}
            />
          </Tooltip>
        ) : (
          <Button
            variant="unstyled"
            size="sm"
            icon="pen"
            onClick={onEdit}
            disabled={disabled}
          />
        )}
      </FloatingStack>
    </FloatingContainer>
  </Stack>
);

export default NewsRow;
