import { Box } from '@alexpireddu/map-ui';
import _ from 'lodash';
import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { Application, Header, Logo, UserMenu, CitySelect } from '..';
import Navigation from './Navigation';
import NewContentButton from './NewContentButton';
import SingleSitePreview from './SingleSitePreview';

export interface DefaultApplicationProps {
  browserTitle?: string;
}

const DefaultApplication: FC<DefaultApplicationProps> = ({
  children,
  browserTitle,
}) => {
  const navigate = useNavigate();

  return (
    <>
      {!_.isNil(browserTitle) && <Helmet title={browserTitle} />}

      <Application
        header={
          <Header
            leftContent={
              <>
                <Box>
                  <Logo clickable onClick={() => navigate('/')} />
                </Box>
                <Box ml="32px">
                  <CitySelect size="md" />
                </Box>
                <Box ml="16px">
                  <SingleSitePreview />
                </Box>
              </>
            }
            rightContent={
              <>
                <Navigation />
                <Box mr="24px" ml="24px">
                  <NewContentButton />
                </Box>
                <Box>
                  <UserMenu />
                </Box>
              </>
            }
          />
        }
      >
        {children}
      </Application>
    </>
  );
};

export default DefaultApplication;
