import { Tooltip } from '@alexpireddu/map-ui';
import styled from '@emotion/styled';
import { FC } from 'react';
import { colors } from '../../theming/theme';

export type DailySlotType = 'M' | 'P' | 'S' | undefined;

const evalutateDailySlot = (dailySlot: number): DailySlotType => {
  switch (dailySlot) {
    case 1:
      return 'M';
    case 2:
      return 'P';
    case 3:
      return 'S';
  }
};

const parseInlineHelp = (dailySlot: number) => {
  switch (dailySlot) {
    case 1:
      return 'Mattino: 6.00 - 11.59';
    case 2:
      return 'Pomeriggio: 12.00 - 17.59';
    case 3:
      return 'Sera: 18.00 - 23.59';
  }
};

export interface DailySlotProps {
  dailySlot: number;
}

const DailySlotContent = styled.div({
  display: 'inline-block',
  color: colors.white,
  backgroundColor: colors.text300,
  fontSize: '14px',
  textTransform: 'uppercase',
  fontWeight: '700',
  padding: '0 5px',
  borderRadius: '4px',
  marginLeft: '8px',
  minWidth: '21px',
  textAlign: 'center',
});

const DailySlot: FC<DailySlotProps> = ({ dailySlot }) => {
  const slot = evalutateDailySlot(dailySlot);

  return (
    <Tooltip content={<span>{parseInlineHelp(dailySlot)}</span>}>
      <DailySlotContent>{slot}</DailySlotContent>
    </Tooltip>
  );
};

export default DailySlot;
