import styled from '@emotion/styled';
import { FC } from 'react';
import { useCityParam } from '../../hooks';
import { SettingsIcon } from '../Icons';
import { NavigationItem } from './NavigationItem';

const SettingsLink = styled(SettingsIcon)({
  position: 'relative',
  width: 28,
  height: 28,
  display: 'block',
});

const Navigation: FC = () => {
  const { currentCityId } = useCityParam();

  return (
    <>
      <NavigationItem link={`/campagne/${currentCityId}`} label="Campagne" />
      <NavigationItem link={`/news/${currentCityId}`} label="News" />
      <NavigationItem
        link={`/configura/${currentCityId}`}
        label={<SettingsLink />}
        isIcon
      />
    </>
  );
};

export default Navigation;
