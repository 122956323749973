import {
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
} from '@alexpireddu/map-ui';
import { FC } from 'react';

export interface CitySelectionModalProps {
  onClose: () => void;
  onConfirm: (newCityId?: string) => void;
  oldCityName?: string;
  newCityName?: string;
  newCityId?: string;
}

const CitySelectionModal: FC<CitySelectionModalProps> = ({
  onClose,
  onConfirm,
  oldCityName,
  newCityName,
  newCityId,
}) => (
  <Modal>
    <ModalHeader>Cambia comune</ModalHeader>
    <ModalBody>
      Hai scelto di passare da "<b>{oldCityName}</b>" a "<b>{newCityName}</b>".
      Continuare?
    </ModalBody>
    <ModalFooter>
      <Button variant="secondary" onClick={() => onClose()}>
        Annulla
      </Button>
      <Button variant="primary" onClick={() => onConfirm(newCityId)}>
        Conferma
      </Button>
    </ModalFooter>
  </Modal>
);

export default CitySelectionModal;
