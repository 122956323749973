import { ActionCreator, bindActionCreators } from 'redux';
import { useMemo } from 'react';
import { PublistaAction } from '../infrastructure/commonTypes';
import { useAppDispatch } from './storeHooks';

export const useAction = <T>(action: ActionCreator<PublistaAction<T>>) => {
  const dispatch = useAppDispatch();

  return useMemo(
    () => bindActionCreators(action, dispatch),
    [dispatch, action]
  );
};

export const useActions = (actions: ActionCreator<PublistaAction>[]) => {
  const dispatch = useAppDispatch();

  return useMemo(
    () => actions.map((a) => bindActionCreators(a, dispatch)),
    [dispatch, actions]
  );
};
