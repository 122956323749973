import { Box, Flex, Text } from '@alexpireddu/map-ui';
import _ from 'lodash';
import { FC, useCallback, useEffect } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { getOnlineSites } from '../../../actions';
import { useAction, useAppSelector, useCityParam } from '../../../hooks';
import SiteItemCard from './SiteItemCard';
import { useVideoService } from '../../../hooks';

const Sites: FC = () => {
  const { currentCityId } = useCityParam();
  const { sites } = useAppSelector((x) => ({ sites: x.sites.data }));
  const videoSitesGet = useAction(getOnlineSites);
  const videoService = useVideoService();

  const loadSites = useCallback(() => {
    videoSitesGet(currentCityId);
  }, [videoSitesGet, currentCityId]);

  useEffect(() => {
    if (!_.isNil(currentCityId)) {
      loadSites();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container fluid>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box ml={12} mt={12} mb={24}>
            <Text as="h2">Impianti</Text>
          </Box>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Flex flexDirection={'row'}>
            {_.map(
              _.sortBy(sites, (s) => s.created_at),
              (s, idx) => (
                <SiteItemCard
                  site={s}
                  key={idx}
                  siteNumber={idx + 1}
                  videoService={videoService}
                />
              )
            )}
          </Flex>
        </Col>
      </Row>
    </Container>
  );
};

export default Sites;
