import { Box, Flex, Text } from '@alexpireddu/map-ui';
import _ from 'lodash';
import { FC, useEffect } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { getCityUsers } from '../../../actions';
import { useActions, useAppSelector } from '../../../hooks';
import { useCityParam } from '../../../hooks/useCityParam';
import EmployeeRecord from './EmployeeRecord';

const Employees: FC = () => {
  const { employees } = useAppSelector((store) => ({
    employees: store.employees,
  }));
  const [getUsers] = useActions([getCityUsers]);

  const { currentCityId } = useCityParam();
  useEffect(() => {
    const { fetching, fetched, error } = employees;
    if (!fetched && !fetching && _.isNil(error)) {
      getUsers(currentCityId);
    }
  }, [employees, getUsers, currentCityId]);

  return (
    <Container fluid>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box ml={12} mt={12} mb={24}>
            <Text as="h2">Collaboratori</Text>
          </Box>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Flex flexDirection={'column'}>
            {_.map(employees.data, (e) => (
              <EmployeeRecord employee={e} key={e.user.id} />
            ))}
          </Flex>
        </Col>
      </Row>
    </Container>
  );
};

export default Employees;
