import { Box, Stepper } from '@alexpireddu/map-ui';
import styled from '@emotion/styled';
import _ from 'lodash';
import { FC, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useLocation } from 'react-router-dom';
import { Application, Header, Logo, UserMenu, CitySelect } from '..';
import BookingFooter from './BookingFooter';

export interface CampaignBookingApplicationProps {
  browserTitle?: string;
}

const FixedStepper = styled(Stepper)({
  width: '1280px',
  minWidth: '1280px',
});

const CampaignBookingApplication: FC<CampaignBookingApplicationProps> = ({
  children,
  browserTitle,
}) => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname.includes('plan')) {
      setActiveStep(0);
    }
    if (pathname.includes('recap')) {
      setActiveStep(1);
    }
  }, [pathname]);

  return (
    <>
      {!_.isNil(browserTitle) && <Helmet title={browserTitle} />}

      <Application
        header={
          <Header
            leftContent={
              <>
                <Box>
                  <Logo clickable onClick={() => navigate('/')} />
                </Box>
                <Box ml="32px">
                  <CitySelect size="md" isDisabled />
                </Box>
              </>
            }
            middleContent={
              <Box minWidth={600} maxWidth={1280} width="100%">
                <FixedStepper
                  activeStep={activeStep}
                  steps={[
                    { label: 'Pianifica comunicazione' },
                    { label: 'Riepilogo' },
                  ]}
                ></FixedStepper>
              </Box>
            }
            rightContent={
              <Box>
                <UserMenu />
              </Box>
            }
          />
        }
        footer={<BookingFooter activeStep={activeStep} />}
        showFooter
      >
        {children}
      </Application>
    </>
  );
};

export default CampaignBookingApplication;
