import { FC, useState, useCallback } from 'react';
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@alexpireddu/map-ui';
import DateRangePicker from './DateRangePicker';
import { Range } from 'react-date-range';
import _ from 'lodash';

export interface DateRangePickerModalProps {
  onClose: Function;
  onConfirm: (fromDate: Date, toDate: Date) => void;
  disabledDates?: Date[];
  range?: Range;
  minDate?: Date;
  maxDate?: Date;
}

const evaluateDates = (ranges?: Range[]) => {
  const range = _.find(ranges, (x) => x.key === 'selection');

  return {
    startDate: range?.startDate,
    endDate: range?.endDate,
  };
};

const DateRangePickerModal: FC<DateRangePickerModalProps> = ({
  onClose,
  onConfirm,
  range = {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  },
  disabledDates,
  minDate,
  maxDate,
}) => {
  const [dates, setDates] = useState<Range[]>([range]);
  const handleClose = useCallback(() => {
    if (_.isFunction(onClose)) {
      onClose();
    }
  }, [onClose]);

  const handleConfirm = useCallback(() => {
    if (_.isFunction(onConfirm)) {
      const newRange = evaluateDates(dates);

      onConfirm(newRange?.startDate, newRange?.endDate);
      onClose();
    }
  }, [onConfirm, onClose, dates]);

  return (
    <Modal onClose={handleClose} size="md">
      <ModalHeader>Seleziona data di inizio e data di fine</ModalHeader>
      <ModalBody>
        <Flex justifyContent={'center'}>
          <DateRangePicker
            editableDateInputs={false}
            onChange={(item) => setDates([item.selection])}
            moveRangeOnFirstSelection={false}
            ranges={dates}
            disabledDates={disabledDates}
            minDate={minDate}
            maxDate={maxDate}
          />
        </Flex>
      </ModalBody>
      <ModalFooter>
        <Button size="md" variant="secondary" onClick={handleClose}>
          Annulla
        </Button>
        <Button size="md" variant="primary" onClick={handleConfirm}>
          Applica
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DateRangePickerModal;
