import { FC } from 'react';
import { Text, Stack, Card, Container } from '@alexpireddu/map-ui';
import styled from '@emotion/styled';

import mobile from '../../assets/mobile.svg';
import city_selection from '../../assets/sfondo_home.png';
import city_selection_mobile from '../../assets/sfondo_home_mobile_XXXL.png';

const Background = styled('div')({
  '@media (min-width: 320px)': {
    backgroundImage: `url(${city_selection_mobile})`,
    backgroundSize: '100%',
    backgroundPosition: 'left bottom',
    backgroundRepeat: 'no-repeat',
  },
  '@media (min-width: 641px)': {
    backgroundImage: `url(${city_selection})`,
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',
  },
  backgroundPosition: 'center',
  position: 'relative',
  minHeight: '100vh',
});

const StyledCard = styled(Card)({
  maxWidth: 700,
  width: 'fit-content',
  boxShadow: '0 8px 32px 0 rgba(23, 148, 202, 0.26);',
});

const SVGImage = styled.img({
  width: '35%',
  alignSelf: 'center',
});

const Mobile: FC = () => (
  <Background>
    <Container maxWidth="md">
      <Stack spacing={6} alignItems="center">
        <div />
        <div>
          <Text as="h1" textAlign="center" lineHeight={1.73}>
            Benvenuto in Publista
          </Text>
          <Text
            as="h3"
            fontWeight="normal"
            textAlign="center"
            lineHeight={1.59}
          >
            La piattaforma per la prenotazione delle pubbliche affissioni
            digitali
          </Text>
        </div>
        <StyledCard>
          <Stack
            spacing={2}
            paddingTop="0.5rem"
            paddingBottom="0.5rem"
            paddingLeft="0.5rem"
            paddingRight="0.5rem"
            alignItems="center"
          >
            <Text fontWeight="bold" fontSize="1.125rem" textAlign="center">
              Collegati da PC o Tablet
              <br />
              per continuare su Publista.
            </Text>
            <SVGImage src={mobile} alt={'PC Tablet'} />
          </Stack>
        </StyledCard>
      </Stack>
    </Container>
  </Background>
);

export default Mobile;
