import _ from 'lodash';
import { RouteProps, useNavigate, useLocation } from 'react-router-dom';
import { getDisplayName } from '@alexpireddu/map-ui';
import { useEffect, useCallback, FC, ComponentType } from 'react';
import {
  getLastCurrentCityId,
  setLastCurrentCityId,
} from '../utils/localStorage';
import {
  useAction,
  useAppSelector,
  useCityParam,
  useCurrentCity,
} from '../hooks';
import { getCityEmergencies } from '../actions';

export type CityRoutingProps = RouteProps;

const withCityRouting = <InjectedProps extends object>(
  WrappedComponent: ComponentType<InjectedProps>
) => {
  const CityRouting: FC<CityRoutingProps & InjectedProps> = ({ ...others }) => {
    const { currentCityId } = useCityParam();
    const cityMatch = useCurrentCity();
    const { cities, emergencies } = useAppSelector((store) => ({
      cities: store.cities,
      emergencies: store.emergencies,
    }));
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const replace = useCallback(
      (to: string) => navigate(to, { replace: true }),
      [navigate]
    );
    const getEmergencies = useAction(getCityEmergencies);

    useEffect(() => {
      if (_.isNil(currentCityId)) {
        const lastCityId = getLastCurrentCityId();

        if (!_.isNil(lastCityId)) {
          replace(`${pathname}/${lastCityId}`);
          if (!emergencies.fetched) {
            getEmergencies(lastCityId);
          }
        } else {
          const firstCity = _.head(cities.data);
          setLastCurrentCityId(firstCity?.id);
          replace(`${pathname}/${firstCity?.id}`);
          if (!emergencies.fetched) {
            getEmergencies(lastCityId);
          }
        }
      } else if (_.isNil(cityMatch)) {
        replace(pathname);
        if (!emergencies.fetched) {
          getEmergencies(cityMatch);
        }
      } else {
        if (!emergencies.fetched) {
          getEmergencies(currentCityId);
        }
      }
    }, [
      currentCityId,
      pathname,
      replace,
      cities,
      cityMatch,
      getEmergencies,
      emergencies,
    ]);

    return <WrappedComponent {...(others as InjectedProps)} />;
  };

  CityRouting.displayName = `CityRouting(${getDisplayName(WrappedComponent)})`;

  return CityRouting;
};

export default withCityRouting;
