import typeToReducer from 'type-to-reducer';
import update from 'immutability-helper';
import { Reducer } from '@reduxjs/toolkit';
import {
  EmergenciesInitialStateType,
  emergenciesInitialState,
} from '../store/initialState';
import { parseAxiosError } from '../utils/axiosResponseHelper';
import { PublistaAction } from '../infrastructure/commonTypes';

export const emergenciesReducer: Reducer<
  EmergenciesInitialStateType,
  PublistaAction
> = typeToReducer<EmergenciesInitialStateType, PublistaAction>(
  {
    CITY_EMERGENCIES_GET: {
      PENDING: (state: EmergenciesInitialStateType) =>
        update(state, {
          fetching: { $set: true },
        }),
      REJECTED: (state: EmergenciesInitialStateType, action: any) => {
        const payload = action?.payload;

        return update(state, {
          fetching: { $set: false },
          fetched: { $set: false },
          error: { $set: parseAxiosError(payload) },
        });
      },
      FULFILLED: (state: EmergenciesInitialStateType, action: any) => {
        const payload = action?.payload;

        return update(state, {
          fetching: { $set: false },
          fetched: { $set: true },
          data: { $set: payload.data },
          error: { $set: null },
        });
      },
    },
  },
  emergenciesInitialState
);
