import { Outlet } from 'react-router-dom';
import { Text, Card, Flex, Box, Stack } from '@alexpireddu/map-ui';
import { FC } from 'react';
import { ConfigureCityMenuItem } from './ConfigureCityMenuItem';
import { DefaultApplication, SettingsIcon } from '../../components';
import { useAppSelector, useCityParam } from '../../hooks';
import withCityRouting from '../../infrastructure/withCityRouting';
import styled from '@emotion/styled';
import _ from 'lodash';

const PageContainer = styled.div({
  display: 'flex',
});

const LeftSideCard = styled(Card)<{ emergencies: boolean }>(
  ({ emergencies }) => ({
    height: `calc(100vh - 150px - ${emergencies ? '50px' : '0px'})`,
    width: '20%',
    margin: '24px',
  })
);

const RightSide = styled.div<{ emergencies: boolean }>(({ emergencies }) => ({
  height: `calc(100vh - 96px - ${emergencies ? '50px' : '0px'})`,
  overflowY: 'auto',
  width: '80%',
  padding: '24px 0',
}));

const CityConfiguration: FC = () => {
  const { currentCityId } = useCityParam();
  const { emergencies } = useAppSelector((x) => ({
    emergencies: _.some(x.emergencies.data),
  }));

  return (
    <DefaultApplication browserTitle="Configura comune">
      <PageContainer>
        <LeftSideCard size="lg" emergencies={emergencies}>
          <Flex flexDirection={'row'}>
            <Box>
              <SettingsIcon />
            </Box>
            <Box ml="8px">
              <Text as="h3">Configurazioni</Text>
            </Box>
          </Flex>
          <Stack marginTop={8}>
            <ConfigureCityMenuItem
              link={`/configura/${currentCityId}/generale`}
              label="Generalità"
            />
            <ConfigureCityMenuItem
              link={`/configura/${currentCityId}/collaboratori`}
              label="Collaboratori"
            />
            <ConfigureCityMenuItem
              link={`/configura/${currentCityId}/impianti`}
              label="Impianti"
            />
          </Stack>
        </LeftSideCard>

        <RightSide emergencies={emergencies}>
          <Outlet />
        </RightSide>
      </PageContainer>
    </DefaultApplication>
  );
};

export default withCityRouting(CityConfiguration);
