import { Box, Button, Card, Flex, Text } from '@alexpireddu/map-ui';
import styled from '@emotion/styled';
import { FC } from 'react';
import { CitySite } from '../../../apis/sitesApi';
import { LiveSiteButton } from '../../../components';
import { colors } from '../../../theming/theme';
import { formatCurrency } from '../../../utils/currencyHelper';
import { openGoogleMapsPin } from '../../../utils/mapsUtils';

export interface SiteItemCardProps {
  site: CitySite;
  siteNumber: number;
  videoService: boolean;
}

const ItemCard = styled(Card)({
  position: 'relative',
  border: 'solid 2px ' + colors.white,
  marginRight: '16px',
});

const SiteNumber = styled.span({
  border: 'solid 1px ' + colors.text400,
  borderRadius: '8px',
  padding: '0 10px',
  fontWeight: 700,
});

const SitePrice = styled.span({
  fontWeight: 700,
  fontSize: '1.25rem',
});

const SiteItemCard: FC<SiteItemCardProps> = ({
  site,
  siteNumber,
  videoService,
}) => (
  <Box mr="24px" mt="12px" mb="12px">
    <ItemCard>
      <Flex alignItems={'center'} mb="16px">
        <Box flexGrow={5}>
          <SiteNumber>{siteNumber}</SiteNumber>
        </Box>
        {videoService && (
          <Box>
            <SitePrice>{formatCurrency(site.price)}</SitePrice>
          </Box>
        )}
      </Flex>
      <Box>
        <Text as="h4">{site.name}</Text>
      </Box>
      <Box mt="16px">
        <Button
          variant="secondary"
          isFullWidth
          icon="map-marker-alt"
          onClick={() => openGoogleMapsPin(site.latitude, site.longitude)}
        >
          Vedi su mappa
        </Button>
      </Box>
      <Box mt="16px">
        <LiveSiteButton siteId={site.id} siteName={site.name} isFullwidth />
      </Box>
    </ItemCard>
  </Box>
);

export default SiteItemCard;
