import { Box, Text } from '@alexpireddu/map-ui';
import { FC } from 'react';
import { VideoTableCampaignInfo } from '../../apis/videoApi';
import { Bullet, Thumbnail } from '../../components';
import { formatCampaignState, CampaignState } from '../../enums/CampaignState';
import { CampaignPurpose } from '../../enums';
import {
  evaluateDurationInDays,
  formatDateLocale,
  formatTime,
} from '../../utils/dateHelper';
import TimeSlots from './TimeSlots';
import _ from 'lodash';
import StopButton from './StopButton';

export interface TableRowProps {
  campaign: VideoTableCampaignInfo;
  purpose: number;
  onReloadCampaigns: () => void;
}

export interface CityAdminVideoTimeSlotGrouped {
  id: string;
  site_id?: string;
  from_date?: string;
  to_date?: string;
  timeSlots: number[];
}

const evaluateBulletVariant = (state: number) => {
  switch (state) {
    case CampaignState.Approved:
      return 'success';
    case CampaignState.Completed:
      return 'warning';
    case CampaignState.InProgress:
      return 'warning';
    default:
      return 'info';
  }
};

const TableRow: FC<TableRowProps> = ({
  campaign,
  purpose,
  onReloadCampaigns,
}) => (
  <tr key={campaign.id}>
    <td>
      <Thumbnail thumb={campaign.image.id} cityId={campaign.city.id} />
    </td>
    <td>
      <span>
        {evaluateDurationInDays(campaign.from_date, campaign.to_date)} giorni
      </span>
      <br />
      <span>
        {formatDateLocale(campaign.from_date)} -{' '}
        {formatDateLocale(campaign.to_date)}
      </span>
    </td>
    <td>
      <span>{campaign.name}</span>
    </td>
    <td>
      <span>{`${campaign.user.name} ${campaign.user.surname}`}</span>
    </td>
    {purpose === CampaignPurpose.Business && !_.isNil(campaign.slots) && (
      <td>
        <TimeSlots slots={campaign.slots} />
      </td>
    )}
    <td>
      <span>{formatDateLocale(campaign.reserved_at)}</span>
      <br />
      <span>Ore {formatTime(campaign.reserved_at)}</span>
    </td>
    <td>
      <Bullet variant={evaluateBulletVariant(campaign.state)} />
      <Box ml="8px" display={'inline'}>
        <Text as="span">{formatCampaignState(campaign.state)}</Text>
      </Box>
      <br />
      <span>{formatDateLocale(campaign.to_date)}</span>
    </td>
    {purpose === CampaignPurpose.Istitutional &&
      campaign.state !== CampaignState.Completed && (
        <td>
          <StopButton campaign={campaign} onReloadCampaigns={onReloadCampaigns}>
            Interrompi
          </StopButton>
        </td>
      )}
  </tr>
);

export default TableRow;
