import { FC } from 'react';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { colors } from '../../theming/theme';
import { Stack } from '@alexpireddu/map-ui';

export interface NewsPreviewProps {
  news: string[];
  charsPerSecond?: number;
}

const Root = styled.div({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: colors.text400,
});

const PanelTitle = styled.div({
  color: colors.text300,
  backgroundColor: '#f2c428',
  textAlign: 'center',
  fontSize: '2em',
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
  fontWeight: 800,
  padding: 10,
});

const Item = styled.div({
  flexShrink: 0,
  boxSizing: 'border-box',
  padding: 10,
  textAlign: 'center',
  fontSize: '2em',
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
  color: colors.white,
  fontWeight: 800,
});

const tickerh = keyframes`
    0% { transform: translateX(100vw); }
    100% { transform: translateX(-100%); }
`;

const Container = styled.div({
  overflow: 'hidden',
  width: '100%',
});

const Scroller = styled.div<{ charsPerSecond: number; charsCount: number }>(
  ({ charsPerSecond, charsCount }) => {
    const dur = Math.round(charsCount / charsPerSecond);
    const minDr = 4;

    return {
      display: 'flex',
      animation: `${tickerh} linear ${dur > minDr ? dur : minDr}s infinite`,
      width: 'fit-content',
    };
  }
);

const NewsPreview: FC<NewsPreviewProps> = ({ news, charsPerSecond = 3 }) => {
  if (news.length === 0) {
    return null;
  }

  const items = news.flatMap((el, i) =>
    i !== news.length - 1
      ? [<Item key={i}>{el}</Item>, <Item key={`${i}-`}>-</Item>]
      : [<Item key={i}>{el}</Item>]
  );
  const charsCount = news.reduce((acc, n) => acc + n.length, 0) + news.length;

  return (
    <Root>
      <Stack direction="row">
        <PanelTitle>News</PanelTitle>
        <Container>
          <Scroller charsPerSecond={charsPerSecond} charsCount={charsCount}>
            {items}
          </Scroller>
        </Container>
      </Stack>
    </Root>
  );
};

export default NewsPreview;
