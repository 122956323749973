import { combineReducers } from '@reduxjs/toolkit';
import { citiesReducer } from './citiesReducer';
import { employeesReducer } from './employeesReducer';
import { sitesReducer } from './sitesReducer';
import { supportReducer } from './supportReducer';
import { userReducer } from './userReducer';
import { newsReducer } from './newsReducer';
import { emergenciesReducer } from './emergenciesReducer';

export const combinedReducer = combineReducers({
  cities: citiesReducer,
  emergencies: emergenciesReducer,
  employees: employeesReducer,
  news: newsReducer,
  sites: sitesReducer,
  support: supportReducer,
  user: userReducer,
});
