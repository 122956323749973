import { Button, Card, DropDown, Stack, Tooltip } from '@alexpireddu/map-ui';
import styled from '@emotion/styled';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router';
import { useCityParam, useVideoService } from '../../hooks';
import { colors } from '../../theming/theme';

const TriggerButton = styled(Button)({
  textTransform: 'uppercase',
  minWidth: 225,
});

const MenuCard = styled(Card)({
  minWidth: 225,
  padding: '0.5rem',
});

const MenuButton = styled(Button)({
  textTransform: 'uppercase',
  textAlign: 'left',
});

const Divider = styled.div({
  margin: '6px 0',
  borderBottom: `solid 1px ${colors.neutral300}`,
});

const NewContentButton: FC = () => {
  const { currentCityId } = useCityParam();
  const navigate = useNavigate();
  const videoService = useVideoService();
  const [isOpen, setOpen] = useState(false);

  const checkVideoService = () => {
    return videoService || true;
  };

  // TODO modifiy logic
  return checkVideoService() ? (
    <DropDown
      open={isOpen}
      onClick={() => setOpen(!isOpen)}
      onClickOutside={() => setOpen(false)}
      trigger={
        <TriggerButton variant="accent" icon="plus">
          Pubblica contenuto
        </TriggerButton>
      }
      content={
        <MenuCard size="sm">
          <Stack direction="column">
            <MenuButton
              isFullWidth
              size="sm"
              variant="unstyled"
              onClick={() => navigate('/prenota/' + currentCityId)}
            >
              Campagna
            </MenuButton>
            <Divider />
            <MenuButton
              isFullWidth
              size="sm"
              variant="unstyled"
              onClick={() => navigate('/news/' + currentCityId + '/edit')}
            >
              News
            </MenuButton>
            <Divider />
            <MenuButton
              isFullWidth
              size="sm"
              variant="unstyled"
              onClick={() => navigate('/emergenze/' + currentCityId + '/edit')}
            >
              Emergenza
            </MenuButton>
          </Stack>
        </MenuCard>
      }
    />
  ) : (
    <Tooltip content={<span>Il comune non è attivo</span>}>
      <TriggerButton variant="accent" icon="plus" disabled>
        Pubblica contenuto
      </TriggerButton>
    </Tooltip>
  );
};

export default NewContentButton;
