import { Box, Button, Flex, Select, Text } from '@alexpireddu/map-ui';
import styled from '@emotion/styled';
import _ from 'lodash';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { Helmet } from 'react-helmet';
import { getSiteFormats } from '../../actions';
import {
  getCampaignsCount,
  getInstitutionalsCampaignsCount,
} from '../../actions/videoActions';
import { CampaignStateCount } from '../../apis/videoApi';
import { DefaultApplication } from '../../components';
import { CampaignPurpose, CampaignState } from '../../enums';
import {
  useAction,
  useActions,
  useAppSelector,
  useCityParam,
} from '../../hooks';
import withCityRouting from '../../infrastructure/withCityRouting';
import CampaignsTable from './CampaignsTable';

const MenuSelect = styled(Select)({
  minWidth: 145,
});

const MenuButton = styled(Button)({
  minWidth: 100,
});

const Campaigns: FC = () => {
  const { currentCityId } = useCityParam();
  const [stateFilter, setStateFilter] = useState<number>(
    CampaignState.InProgress
  );
  const [stateCount, setStateCount] = useState<Array<CampaignStateCount>>([]);
  const [purpose, setPurpose] = useState<number>(CampaignPurpose.Istitutional);
  const { siteFormats } = useAppSelector((store) => ({
    siteFormats: store.support.siteFormats,
  }));

  const getFormats = useAction(getSiteFormats);
  const [videoCampaignsCountGet, videoInstitutionalsCountGet] = useActions([
    getCampaignsCount,
    getInstitutionalsCampaignsCount,
  ]);

  useEffect(() => {
    const { fetched, fetching, error } = siteFormats;
    if (!fetched && !fetching && _.isNil(error)) {
      getFormats();
    }
  }, [siteFormats, getFormats]);

  const loadCampaigns = useCallback(() => {
    if (!_.isNil(currentCityId)) {
      if (purpose === CampaignPurpose.Business) {
        videoCampaignsCountGet(currentCityId).then((resp: any) =>
          setStateCount(resp.value.data)
        );
      }
      if (purpose === CampaignPurpose.Istitutional) {
        videoInstitutionalsCountGet(currentCityId).then((resp: any) =>
          setStateCount(resp.value.data)
        );
      }
    }
  }, [
    currentCityId,
    videoCampaignsCountGet,
    videoInstitutionalsCountGet,
    purpose,
  ]);

  useEffect(() => {
    loadCampaigns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const approvedCount = useMemo(
    () => stateCount.find((x) => x.state === CampaignState.Approved),
    [stateCount]
  );

  const progressCount = useMemo(
    () => stateCount.find((x) => x.state === CampaignState.InProgress),
    [stateCount]
  );

  const completedCount = useMemo(
    () => stateCount.find((x) => x.state === CampaignState.Completed),
    [stateCount]
  );

  return (
    <DefaultApplication>
      <Helmet title="Campagne" />

      <Container fluid className="pa__campaigns">
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Flex mt="24px">
              <Box mr="16px">
                <MenuSelect
                  size="sm"
                  onChange={(val) => setPurpose(val as number)}
                  value={purpose}
                  options={[
                    {
                      label: 'Comunali',
                      value: CampaignPurpose.Istitutional,
                    },
                    { label: 'Commerciali', value: CampaignPurpose.Business },
                  ]}
                ></MenuSelect>
              </Box>
              <Box flexGrow={6} textAlign="right">
                <MenuButton
                  mr={16}
                  variant={
                    stateFilter === CampaignState.InProgress
                      ? 'primary'
                      : 'unstyled'
                  }
                  size="sm"
                  onClick={() => setStateFilter(CampaignState.InProgress)}
                >
                  Attive
                  {!_.isNil(progressCount) && progressCount?.count !== 0 && (
                    <Text as="span"> ({progressCount?.count})</Text>
                  )}
                </MenuButton>
                <MenuButton
                  mr={16}
                  variant={
                    stateFilter === CampaignState.Approved
                      ? 'primary'
                      : 'unstyled'
                  }
                  size="sm"
                  onClick={() => setStateFilter(CampaignState.Approved)}
                >
                  Prossime
                  {!_.isNil(approvedCount) && approvedCount?.count !== 0 && (
                    <Text as="span"> ({approvedCount?.count})</Text>
                  )}
                </MenuButton>
                <MenuButton
                  variant={
                    stateFilter === CampaignState.Completed
                      ? 'primary'
                      : 'unstyled'
                  }
                  size="sm"
                  onClick={() => setStateFilter(CampaignState.Completed)}
                >
                  Concluse
                  {!_.isNil(completedCount) && completedCount?.count !== 0 && (
                    <Text as="span"> ({completedCount?.count})</Text>
                  )}
                </MenuButton>
              </Box>
            </Flex>
            <Box mt="24px">
              <CampaignsTable
                filteredState={stateFilter}
                filteredPurpose={purpose}
                onReloadCampaigns={loadCampaigns}
              />
            </Box>
          </Col>
        </Row>
      </Container>
    </DefaultApplication>
  );
};

export default withCityRouting(Campaigns);
