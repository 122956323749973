import styled from '@emotion/styled';
import _ from 'lodash';
import { FC } from 'react';
import { useResizedImage } from '../../hooks';
import placeholder from '../../assets/image-placeholder-w105.jpg';

export interface ThumbnailProps {
  cityId: string;
  thumb: string;
  campaignName: string;
  size?: 'normal' | 'small';
}

const ThumbImageNormal = styled.img({
  maxWidth: 105,
  height: 'auto',
  width: 'auto',
  padding: '0',
  margin: '0',
  aspectRatio: '4 / 3',
  objectFit: 'contain',
});

const ThumbImageSmall = styled.img({
  maxWidth: 40,
  height: 'auto',
  width: 'auto',
  padding: '0',
  margin: '0',
  aspectRatio: '4 / 3',
  objectFit: 'contain',
});

// eslint-disable-next-line no-empty-pattern
const Thumbnail: FC<ThumbnailProps> = ({
  cityId,
  thumb,
  campaignName,
  size = 'normal',
}) => {
  const uri = useResizedImage(cityId, thumb);

  if (_.isNil(uri)) {
    return size === 'normal' ? (
      <ThumbImageNormal
        alt={campaignName}
        src={placeholder}
        title={campaignName}
      />
    ) : (
      <ThumbImageSmall
        alt={campaignName}
        src={placeholder}
        title={campaignName}
      />
    );
  }

  return size === 'normal' ? (
    <ThumbImageNormal alt={campaignName} src={uri} title={campaignName} />
  ) : (
    <ThumbImageSmall alt={campaignName} src={uri} title={campaignName} />
  );
};

export default Thumbnail;
