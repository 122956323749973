export const CampaignPurpose = {
  Business: 1,
  Istitutional: 2,
};

export type CampaignPurposeType = typeof CampaignPurpose;

export const formatCampaignPurpose = (stato: number) => {
  switch (stato) {
    case CampaignPurpose.Business:
      return 'Commerciale';
    case CampaignPurpose.Istitutional:
      return 'Comunale';
    default:
      return 'Sconosciuto';
  }
};
