import { Text } from '@alexpireddu/map-ui';
import styled from '@emotion/styled';
import { FC } from 'react';
import { colors } from '../../theming/theme';

const TableRow = styled.tr({
  backgroundColor: colors.grey200,
  color: colors.neutral400,
});

interface NoResultsRowProps {
  colSpan: number;
}

const NoResultsRow: FC<NoResultsRowProps> = ({ colSpan }) => (
  <TableRow>
    <td colSpan={colSpan}>
      <Text as="h4" textAlign={'center'}>
        Nessun risultato
      </Text>
    </td>
  </TableRow>
);

export default NoResultsRow;
