import { Button, Tooltip } from '@alexpireddu/map-ui';
import styled from '@emotion/styled';
import { FC } from 'react';
import { colors } from '../../theming/theme';
import { openLedwallPreview } from '../../utils/ledwallPreview';

export interface LiveSiteButtonProps {
  siteId: string;
  siteName?: string;
  isFullwidth?: boolean;
}

const LiveButton = styled(Button)({
  backgroundColor: 'rgb(255,0,0)',
  borderColor: 'rgb(255,0,0)',
  textTransform: 'uppercase',
  ':hover': {
    backgroundColor: colors.danger300,
    borderColor: colors.danger300,
  },
});

const LiveSiteButton: FC<LiveSiteButtonProps> = ({
  siteId,
  siteName = '',
  isFullwidth = false,
}) => {
  return isFullwidth ? (
    <LiveButton
      variant="error"
      size="sm"
      icon="circle"
      isFullWidth
      onClick={() => openLedwallPreview(siteId)}
    >
      Live
    </LiveButton>
  ) : (
    <Tooltip
      content={
        <span>
          Vedi in tempo reale l'impianto
          <br />
          {siteName}
        </span>
      }
    >
      <LiveButton
        variant="error"
        size="sm"
        icon="circle"
        onClick={() => openLedwallPreview(siteId)}
      >
        Live
      </LiveButton>
    </Tooltip>
  );
};

export default LiveSiteButton;
