import { NotificationManager, Select, MapUiSize } from '@alexpireddu/map-ui';
import { SelectProps } from '@alexpireddu/map-ui/dist/components/Form/Select';
import styled from '@emotion/styled';
import _ from 'lodash';
import { FC, useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCities, getProvinceInfo, getOnlineSites } from '../../actions';
import { ProvinceInfo } from '../../apis/supportApi';
import { useAppSelector, useCurrentCity } from '../../hooks';
import { useAction } from '../../hooks/useActions';
import { setLastCurrentCityId } from '../../utils/localStorage';
import { useModalProvider } from '../ModalProvider';
import CitySelectionModal, {
  CitySelectionModalProps,
} from './CitySelectionModal';

export interface CitySelectProps extends SelectProps {
  size?: MapUiSize;
  isFullWidth?: boolean;
}

const InternalSelect = styled(Select)({
  minWidth: '160px',
});

const CitySelect: FC<CitySelectProps> = ({
  isFullWidth,
  size = 'sm',
  isDisabled,
  ...others
}) => {
  const { cities } = useAppSelector((store) => ({
    cities: store.cities,
  }));
  const [cityProvince, setCityProvince] = useState<ProvinceInfo>();
  const citiesGet = useAction(getCities);
  const getProvinceDetail = useAction(getProvinceInfo);
  const getSites = useAction(getOnlineSites);
  const navigate = useNavigate();
  const currentCity = useCurrentCity();
  const { openModal, closeModal } = useModalProvider();

  useEffect(() => {
    const { fetching, fetched, error } = cities;
    if (!fetched && !fetching && _.isNil(error)) {
      citiesGet();
    }
  }, [cities, citiesGet]);

  useEffect(() => {
    if (!_.isNil(currentCity)) {
      getProvinceDetail(currentCity?.province.id).then((resp: any) =>
        setCityProvince(resp.value.data)
      );
    }
  }, [getProvinceDetail, currentCity]);

  const handleCloseModal = useCallback(() => {
    closeModal({ identifier: 'CitySelectionModal' });
  }, [closeModal]);

  const handleConfirmModal = useCallback(
    (newCityId?: string) => {
      closeModal({ identifier: 'CitySelectionModal' });

      setLastCurrentCityId(newCityId);
      const newCity = _.find(cities.data, (x) => x.id === newCityId);
      getSites(newCityId);

      NotificationManager.notify({
        type: 'success',
        message: `Comune selezionato: ${newCity?.name}`,
      });

      navigate(`/campagne/${newCityId}`, {
        replace: true,
      });
    },
    [cities.data, navigate, closeModal, getSites]
  );

  const handleChangeSelect = useCallback(
    (newVal: string | number) => {
      const nextCity = _.find(cities.data, (x) => x.id === newVal);

      openModal<CitySelectionModalProps>({
        identifier: 'CitySelectionModal',
        //lazyImportFn: () => import('./CitySelectionModal'),
        component: CitySelectionModal,
        additionalProps: {
          oldCityName: currentCity?.name,
          newCityName: nextCity?.name,
          newCityId: newVal as string,
          onClose: handleCloseModal,
          onConfirm: handleConfirmModal,
        },
      });
    },
    [
      cities.data,
      currentCity?.name,
      handleCloseModal,
      handleConfirmModal,
      openModal,
    ]
  );

  return (
    <InternalSelect
      isDisabled={cities.fetching || isDisabled}
      value={currentCity?.id}
      onChange={handleChangeSelect}
      isFullWidth={isFullWidth}
      size={size}
      options={_.map(cities.data, (city) => ({
        value: city.id,
        label:
          city.name + (!_.isNil(cityProvince) ? ', ' + cityProvince.code : ''),
      }))}
      {...others}
    />
  );
};

export default CitySelect;
