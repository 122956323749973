import { render } from 'react-dom';
import { RouterProvider } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import './theming/App.scss';
import { MainUIProviders } from './components';

import { isDevMode } from './infrastructure/environment';
import { router } from './routes';
import { initializeSentry } from './infrastructure/sentryConfiguration';

initializeSentry();

render(
  <MainUIProviders>
    <RouterProvider router={router} />
  </MainUIProviders>,
  document.getElementById('root')
);

if (isDevMode()) {
  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals(console.info);
}
