import { ActionCreator } from 'redux';
import { getCitiesV1, getCityUsersV1 } from '../apis/cityApis';
import { actionFactory } from '../infrastructure/actionFactory';
import { PublistaAction } from '../infrastructure/commonTypes';

export const getCityUsers: ActionCreator<PublistaAction> = (cityId: string) =>
  actionFactory('CITY_USERS_GET', getCityUsersV1(cityId));

export const getCities: ActionCreator<PublistaAction> = () =>
  actionFactory('CITIES_GET', getCitiesV1());
