import { DropDown } from '@alexpireddu/map-ui';
import { useState, FC } from 'react';
import Content from './Content';
import { Trigger } from './Trigger';
import { useAppSelector } from '../../hooks';

export const UserMenu: FC = () => {
  const [menuOpen, setMenuOpen] = useState<boolean>();
  const { user } = useAppSelector((store) => ({
    user: store.user,
  }));

  return (
    <DropDown
      open={menuOpen}
      onClick={() => setMenuOpen(!menuOpen)}
      onClickOutside={() => setMenuOpen(false)}
      trigger={<Trigger name={user.data?.name + ' ' + user.data?.surname} />}
      content={<Content />}
    />
  );
};
