import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
} from '@alexpireddu/map-ui';
import styled from '@emotion/styled';
import { FC } from 'react';
import { useOriginalImage } from '../../hooks';
import placeholder from '../../assets/image-placeholder-w720.jpg';
import _ from 'lodash';

export interface PreviewImageModalProps {
  imageId: string;
  cityId: string;
  onClose: Function;
}

const PreviewImage = styled.img({
  maxWidth: '720px',
  padding: '0',
  margin: '0',
  aspectRatio: '16 / 9',
  objectFit: 'contain',
});

interface PreviewProps {
  imageId: string;
  cityId: string;
}

const Preview: FC<PreviewProps> = ({ imageId, cityId }) => {
  const uri = useOriginalImage(cityId, imageId);

  return (
    <PreviewImage
      alt="Contenuto campagna"
      src={_.isNil(uri) ? placeholder : uri}
      title="Contenuto campagna"
    />
  );
};

const PreviewImageModal: FC<PreviewImageModalProps> = ({
  onClose,
  imageId,
  cityId,
}) => (
  <Modal size="xl" onClickOutside={() => onClose()}>
    <ModalHeader>
      <ModalCloseButton onClick={() => onClose()} />
    </ModalHeader>
    <ModalBody>
      <Preview imageId={imageId} cityId={cityId} />
    </ModalBody>
  </Modal>
);

export default PreviewImageModal;
