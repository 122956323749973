import { Stack, Text } from '@alexpireddu/map-ui';
import { FC, useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { DefaultApplication, useModalProvider } from '../../components';
import { useAction, useAppSelector, useCityParam } from '../../hooks';
import ManageNewsModal, { ManageNewsModalProps } from './ManageNewsModal';
import NewsList from './NewsList';
import { getCityNews } from '../../actions';
import _ from 'lodash';
import { evaluateExpirationDaysSinceToday } from '../../utils/dateHelper';
import NewsPreview from './NewsPreview';
import withCityRouting from '../../infrastructure/withCityRouting';
import EmergenciesPreview from '../Emergencies/EmergenciesPreview';

export const NEWS_DURATION_DAYS = 20;
export const NEWS_MAX_LENGTH = 150;
export const NEWS_LIST_MAX_SIZE = 10;

export interface NewsPageProps {
  editMode?: boolean;
}

const NewsPage: FC<NewsPageProps> = ({ editMode }) => {
  const { currentCityId } = useCityParam();
  const { openModal, closeModal } = useModalProvider();
  const { emergencies, news } = useAppSelector((store) => ({
    emergencies: store.emergencies.data,
    news: _.filter(
      _.map(store.news.data, (n) => ({
        ...n,
        expiration: evaluateExpirationDaysSinceToday(
          n.created_at,
          NEWS_DURATION_DAYS
        ),
      })),
      (x) => x.expiration > 0
    ),
  }));
  const getNews = useAction(getCityNews);

  const loadNews = useCallback(() => {
    return getNews(currentCityId);
  }, [currentCityId, getNews]);

  const handleClose = useCallback(
    (reload: boolean) => {
      closeModal({
        identifier: 'ManageNewsModal',
      });

      if (reload) {
        loadNews();
      }
    },
    [closeModal, loadNews]
  );

  const handleOpen = useCallback(() => {
    openModal<ManageNewsModalProps>({
      component: ManageNewsModal,
      identifier: 'ManageNewsModal',
      additionalProps: {
        news: news,
        onClose: handleClose,
        cityId: currentCityId!,
      },
    });
  }, [currentCityId, handleClose, openModal, news]);

  useEffect(() => {
    loadNews().then(() => {
      if (editMode) {
        handleOpen();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DefaultApplication>
      <Helmet title="News" />

      <div style={{ maxWidth: '1080px', margin: '0 auto' }}>
        <Stack marginTop={24} spacing={4}>
          <Text as="h2">News</Text>
          <NewsList news={news} onEdit={handleOpen} />
        </Stack>
      </div>

      {_.some(emergencies) ? (
        <EmergenciesPreview list={_.map(emergencies, (n) => n.text)} />
      ) : (
        <NewsPreview news={_.map(news, (n) => n.text)} />
      )}
    </DefaultApplication>
  );
};

export default withCityRouting(NewsPage);
