import { FC, HTMLAttributes } from 'react';
import styled from '@emotion/styled';
import { colors } from '../../theming/theme';

export interface LabelProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  state?: 'error' | 'success';
}

const LabelContainer = styled.div<LabelProps>(({ state }) => ({
  fontFamily: 'Titillium Web',
  fontSize: '14px',
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  letterSpacing: 'normal',
  lineHeight: '27px',
  color: colors.text300,
  marginTop: '4px',
  ...(state === 'error' && { color: colors.danger300 }),
  ...(state === 'success' && { color: colors.success300 }),
}));

const Label: FC<LabelProps> = ({ children, ...others }) => (
  <LabelContainer {...others}>{children}</LabelContainer>
);

export default Label;
