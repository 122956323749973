import isPromise from 'is-promise';
import _ from 'lodash';
import { Dispatch, Middleware } from 'redux';
import { getAuthRedirectUri } from '../infrastructure/environment';
import { PublistaAction } from '../infrastructure/commonTypes';
import { AxiosError } from 'axios';

const redirectUnauthResponse = (error: AxiosError) => {
  if (error.code === '401') {
    // eslint-disable-next-line no-restricted-globals
    window.location.replace(getAuthRedirectUri());
  }
};

const authMiddleware: Middleware<Dispatch<PublistaAction>> =
  () => (next: Dispatch<PublistaAction>) => (action: PublistaAction) => {
    const { payload, error } = action;

    if (!_.isNil(error)) {
      const {
        response: { status },
      } = payload;

      if (status === 401) {
        // eslint-disable-next-line no-restricted-globals
        location.replace(getAuthRedirectUri());
      }
    }

    if (!isPromise(payload)) {
      return next(action);
    }

    // eslint-disable-next-line @typescript-eslint/no-shadow
    return next(action).catch((error: any) => {
      redirectUnauthResponse(error);

      return error;
    });
  };

export default authMiddleware;
