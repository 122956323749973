import { FC, FocusEvent } from 'react';
import { Box, Button, Flex, Input, Stack, Text } from '@alexpireddu/map-ui';
import { NEWS_MAX_LENGTH } from './NewsPage';
import Timer from './Timer';
import DragIndicatorIcon from '../../components/Icons/DragIndicatorIcon';
import styled from '@emotion/styled';

const FloatingContainer = styled.div({
  position: 'relative',
});

const FloatingInput = styled(Input)({
  paddingRight: 80,
});

const FloatingStack = styled(Stack)({
  position: 'absolute',
  right: 2,
  top: 1,
});

export interface EditRowProps {
  expiration?: number;
  index: number;
  onChangeValue: (idx: number, val: string) => void;
  onDeleteItem: (idx: number) => void;
  text?: string;
  defaultItem: boolean;
}

const EditRow: FC<EditRowProps> = ({
  expiration,
  index,
  onChangeValue,
  onDeleteItem,
  text,
  defaultItem,
}) => {
  const handleBlur = (evt: FocusEvent<HTMLInputElement>) => {
    if (evt.target.value.trim() === '') {
      onDeleteItem(index);
    }
  };

  return (
    <>
      <Stack direction="row">
        <Flex alignContent={'center'}>
          <DragIndicatorIcon />
        </Flex>
        <Box flexGrow={5}>
          <FloatingContainer>
            <FloatingInput
              value={text}
              isFullWidth
              placeholder="Inserisci qui una nuova news"
              maxLength={NEWS_MAX_LENGTH}
              onChange={(evt) => onChangeValue(index, evt.target.value)}
              onBlur={handleBlur}
            />
            {!defaultItem && (
              <FloatingStack spacing={1} direction="row">
                {expiration && <Timer expiration={expiration!} />}
                <Button
                  variant="unstyled"
                  icon="trash"
                  size="sm"
                  onClick={() => onDeleteItem(index)}
                  tabIndex={-1}
                />
              </FloatingStack>
            )}
          </FloatingContainer>
        </Box>
      </Stack>
      <Text color="grey" textAlign="right" fontSize={12}>
        {text?.length ?? 0} / {NEWS_MAX_LENGTH}
      </Text>
    </>
  );
};

export default EditRow;
