import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@alexpireddu/map-ui';
import { FC } from 'react';

export interface StopEmergenciesModalProps {
  onClose: () => void;
  onConfirm: () => void;
}

const StopEmergenciesModal: FC<StopEmergenciesModalProps> = ({
  onClose,
  onConfirm,
}) => (
  <Modal size="md">
    <ModalHeader>Interrompi emergenze</ModalHeader>
    <ModalBody>
      <span>Sei sicuro di voler interrompere le news di emergenza?</span>
    </ModalBody>
    <ModalFooter>
      <Button variant="secondary" ml="8px" onClick={onClose}>
        Annulla
      </Button>
      <Button variant="primary" mr="8px" onClick={onConfirm}>
        Conferma
      </Button>
    </ModalFooter>
  </Modal>
);

export default StopEmergenciesModal;
