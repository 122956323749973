import { Button } from '@alexpireddu/map-ui';
import styled from '@emotion/styled';
import { FC, useCallback } from 'react';
import { useModalProvider } from '../../components';
import { colors } from '../../theming/theme';
import { VideoTableCampaignInfo } from '../../apis/videoApi';
import StopCampaignModal, { StopCampaignModalProps } from './StopCampaignModal';
import { useAction } from '../../hooks';
import { stopInstitutionalCampaign } from '../../actions/videoActions';

export interface StopButtonProps {
  campaign: VideoTableCampaignInfo;
  onReloadCampaigns: Function;
}

const ButtonStyled = styled(Button)({
  backgroundColor: colors.warning300,
  color: colors.text400,
  border: 'none',
});

const StopButton: FC<StopButtonProps> = ({ campaign, onReloadCampaigns }) => {
  const { openModal, closeModal } = useModalProvider();
  const stopCampaign = useAction(stopInstitutionalCampaign);

  const handleCloseModal = useCallback(() => {
    closeModal({
      identifier: 'StopCampaignModal',
    });
  }, [closeModal]);

  const handleConfirmStop = useCallback(() => {
    stopCampaign(campaign.city.id!, campaign.id)
      .then(() => {
        onReloadCampaigns();
      })
      .finally(() => {
        closeModal({
          identifier: 'StopCampaignModal',
        });
      });
  }, [
    closeModal,
    onReloadCampaigns,
    stopCampaign,
    campaign.city.id,
    campaign.id,
  ]);

  const handleClick = useCallback(() => {
    openModal<StopCampaignModalProps>({
      identifier: 'StopCampaignModal',
      component: StopCampaignModal,
      additionalProps: {
        onClose: handleCloseModal,
        onConfirm: handleConfirmStop,
      },
    });
  }, [openModal, handleCloseModal, handleConfirmStop]);

  return (
    <ButtonStyled size="sm" onClick={handleClick}>
      Interrompi
    </ButtonStyled>
  );
};

export default StopButton;
