import _ from 'lodash';

export const evaluateDurationInDays = (fromDate: string, toDate: string) => {
  const startDate = new Date(fromDate);
  const endDate = new Date(toDate);

  const diff = endDate.getTime() - startDate.getTime();

  return Math.ceil(diff / (1000 * 3600 * 24));
};

export const addWeeks = (date: Date, weeks: number) => {
  date.setDate(date.getDate() + 7 * weeks);
  return date;
};

export const addYears = (date: Date, years: number) => {
  date.setFullYear(date.getFullYear() + years);

  return date;
};

const padTo2Digits = (num: number) => {
  return num.toString().padStart(2, '0');
};

export const formatDateISOShort = (dateString?: string) => {
  if (_.isNil(dateString)) {
    return null;
  }
  var d = new Date(dateString);

  return [
    d.getFullYear(),
    padTo2Digits(d.getMonth() + 1),
    padTo2Digits(d.getDate()),
  ].join('-');
};

export const formatDateLocale = (dateString?: string) => {
  if (_.isNil(dateString)) {
    return null;
  }

  const dateFormatter = new Intl.DateTimeFormat(navigator.language, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  return dateFormatter.format(new Date(dateString));
};

export const formatTime = (dateString?: string) => {
  if (_.isNil(dateString)) {
    return null;
  }

  const dateFormatter = new Intl.DateTimeFormat(navigator.language, {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });

  return dateFormatter.format(new Date(dateString));
};

export const formatDayMonth = (dateString?: string) => {
  if (_.isNil(dateString)) {
    return null;
  }

  const dateFormatter = new Intl.DateTimeFormat(navigator.language, {
    day: '2-digit',
    month: 'short',
  });

  return dateFormatter.format(new Date(dateString));
};

export const nextDay = (dateString: string) => {
  const date = new Date(dateString);
  date.setDate(date.getDate() + 1);

  return formatDateISOShort(date.toISOString());
};

export const prevDay = (dateString: string) => {
  const date = new Date(dateString);
  date.setDate(date.getDate() - 1);

  return formatDateISOShort(date.toISOString());
};

export const getDaysArray = (fromDate: string, toDate: string) => {
  for (
    var days = [], dt = new Date(fromDate);
    dt <= new Date(toDate);
    dt.setDate(dt.getDate() + 1)
  ) {
    days.push(new Date(dt));
  }
  return days;
};

export const evaluateExpirationDaysSinceToday = (
  fromDate: string,
  duration: number
) => {
  const date = new Date(fromDate);
  date.setDate(date.getDate() + duration);

  const today = formatDateISOShort(new Date().toString());

  const remaining = evaluateDurationInDays(
    today!,
    formatDateISOShort(date.toString())!
  );

  return remaining;
};

export const resetTime = (dateString: string) => {
  const date = new Date(dateString);

  date.setHours(0);
  date.setMilliseconds(0);
  date.setMinutes(0);
  date.setSeconds(0);

  return date;
};
