import { FC } from 'react';
import { Avatar } from '@alexpireddu/map-ui';

export interface TriggerProps {
  name: string;
}

export const Trigger: FC<TriggerProps> = ({ name }) => (
  <Avatar name={name} variant="light" />
);
