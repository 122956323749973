import { FC, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Text, Stack } from '@alexpireddu/map-ui';
import { useFormContext } from 'react-hook-form';
import { WizardFormValues } from './WizardForm';
import { useAppSelector } from '../../hooks';
import { formatDayMonth } from '../../utils/dateHelper';

const Paper = styled('div')({
  borderRadius: 12,
  padding: 12,
  border: '1px solid #cfd8dc',
  backgroundColor: 'rgba(236, 239, 241, 0.5)',
});

const TextsContainer = styled('div')({
  flex: 1,
});

const StyledText = styled(Text)({
  display: 'flex',
  gap: 4,
  alignItems: 'center',
  flexWrap: 'wrap',
  marginBottom: 3,
});

const Bold = styled('span')({
  fontWeight: 'bold',
});

const Image = styled('img')({
  aspectRatio: '16 / 9',
  minWidth: 105,
  maxWidth: 105,
  width: 105,
  objectFit: 'contain',
  marginTop: 8,
});

const ImageContainer = styled(Stack)({
  textAlign: 'center',
});

const DateSlot = styled.span({
  textTransform: 'uppercase',
});

const CampaignCard: FC = () => {
  const [URI, setURI] = useState('');
  const { user } = useAppSelector((store) => ({
    user: store.user.data,
  }));
  const { getValues } = useFormContext<WizardFormValues>();
  const { name, file, fromDate, toDate } = getValues();

  useEffect(() => {
    if (file) {
      setURI(URL.createObjectURL(file));
    }

    return () => {
      setURI((last) => {
        URL.revokeObjectURL(last);
        return '';
      });
    };
  }, [file]);

  return (
    <Stack as={Paper} direction="row" spacing={1}>
      <TextsContainer>
        <StyledText>
          <Bold>Dati richiedente: </Bold>
          {`${user.name} ${user.surname}`}
        </StyledText>
        <StyledText>
          <Bold>Nome comunicazione: </Bold>
          {name}
        </StyledText>
        <StyledText>
          <Bold>Spazi: </Bold>
          <DateSlot>{formatDayMonth(fromDate)}</DateSlot> -{' '}
          <DateSlot>{formatDayMonth(toDate)}</DateSlot>
        </StyledText>
      </TextsContainer>
      {URI && (
        <ImageContainer>
          <Bold>Contenuto: </Bold>
          <Image src={URI} alt="campaign" />
        </ImageContainer>
      )}
    </Stack>
  );
};

export default CampaignCard;
