import { Box, Card, Stack, Text } from '@alexpireddu/map-ui';
import { FC } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { CityLogo } from '../../../components';
import { useCurrentCity } from '../../../hooks';

const Overview: FC = () => {
  const selectedCity = useCurrentCity();

  return (
    <Container fluid>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box ml={12} mt={12} mb={24}>
            <Text as="h2">Generalità comune</Text>
          </Box>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box mb={24}>
            <Card size="xl">
              <Text as="h3" marginBottom="24px">
                Comune di
              </Text>
              <Stack direction="row" spacing={2} alignItems="center">
                <CityLogo cityId={selectedCity?.id!} />
                <Stack>
                  <Text as="h3" marginBottom="0" lineHeight={'2rem'}>
                    {selectedCity?.name}
                  </Text>
                  <Text as="p">Provincia: {selectedCity?.province.name}</Text>
                </Stack>
              </Stack>
            </Card>
          </Box>
        </Col>
      </Row>
    </Container>
  );
};

export default Overview;
