import { get } from '../infrastructure/webRequest';

export type CitySite = {
  active: boolean;
  created_at: string;
  format: {
    height: number;
    id: string;
    width: number;
  };
  id: string;
  latitude: string;
  longitude: string;
  name: string;
  price: number;
};

export type CitySites = Array<CitySite>;

export const getOnlineSitesV1 = (cityId: string) =>
  get<CitySites>(`/v1/cityadmin/city/${cityId}/video/sites/online`);
