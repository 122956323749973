import { SortType } from '@alexpireddu/map-ui/dist/components/Table/AdvancedTable';
import { ActionCreator } from 'redux';
import {
  createInstitutionalCampaignV1,
  getCampaignsCountV1,
  getCampaignsV1,
  getInstitutionalCampaignsCountV1,
  getInstitutionalCampaignsV1,
  getInstitutionalCampaignsApprovedV1,
  stopInstitutionalCampaignV1,
} from '../apis/videoApi';
import { actionFactory } from '../infrastructure/actionFactory';
import { PublistaAction } from '../infrastructure/commonTypes';

export const getCampaignsCount: ActionCreator<PublistaAction> = (
  cityId: string
) => actionFactory('CAMPAIGNS_COUNT_GET', getCampaignsCountV1(cityId));

export const getInstitutionalsCampaignsCount: ActionCreator<PublistaAction> = (
  cityId: string
) =>
  actionFactory(
    'INSTITUTIONAL_CAMPAIGNS_COUNT_GET',
    getInstitutionalCampaignsCountV1(cityId)
  );

export const getCampaigns: ActionCreator<PublistaAction> = (
  cityId: string,
  pageNumber: number,
  pageSize: number,
  state: number,
  sortProperty?: string,
  sortOrder?: SortType
) =>
  actionFactory(
    'CAMPAIGNS_PAGINATED_GET',
    getCampaignsV1(cityId, pageNumber, pageSize, state, sortProperty, sortOrder)
  );

export const getInstitutionalCampaigns: ActionCreator<PublistaAction> = (
  cityId: string,
  pageNumber: number,
  pageSize: number,
  state: number,
  sortProperty?: string,
  sortOrder?: SortType
) =>
  actionFactory(
    'INSTITUTIONAL_CAMPAIGNS_PAGINATED_GET',
    getInstitutionalCampaignsV1(
      cityId,
      pageNumber,
      pageSize,
      state,
      sortProperty,
      sortOrder
    )
  );

export const getInstitutionalCampaignsApproved = (
  cityId: string,
  fromDate?: string,
  toDate?: string
) =>
  actionFactory(
    'APPROVED_INSTITUTIONAL_CAMPAIGNS_GET',
    getInstitutionalCampaignsApprovedV1(cityId, fromDate, toDate)
  );

export const createInstitutionalCampaign = (cityId: string, data: FormData) =>
  actionFactory(
    'INSTITUTIONAL_CAMPAIGN_CREATE',
    createInstitutionalCampaignV1(cityId, data)
  );

export const stopInstitutionalCampaign = (cityId: string, campaignId: string) =>
  actionFactory(
    'INSTITUTIONAL_CAMPAIGN_STOP',
    stopInstitutionalCampaignV1(cityId, campaignId)
  );
