import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Stack,
} from '@alexpireddu/map-ui';
import _ from 'lodash';
import { v1 as uuid } from 'uuid';
import { FC, useState, useEffect, useCallback } from 'react';
import { getOnlineSites, updateCityEmergencies } from '../../actions';
import {
  CitySiteNews,
  CitySiteNewsItem,
  UpdateCityNewsItem,
} from '../../apis/newsApis';
import { useActions, useAppSelector } from '../../hooks';
import {
  Draggable,
  DragDropContext,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { NEWS_LIST_MAX_SIZE } from './EmergenciesPage';
import EditRow from './EditRow';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

// a little function to help us with reordering the result
const reorder = (list: any[], startIndex: number, endIndex: number): any[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const animation = keyframes`
    0% { opacity: 0; }
    100% { opacity: 1; }
`;
const TransitionContainer = styled.div({
  animation: `${animation} 0.3s linear`,
});

export interface ManageEmergenciesModalProps {
  emergencies: CitySiteNews;
  cityId: string;
  onClose: (reload: boolean) => void;
}

export type EditCitySiteEmergencyItem = CitySiteNewsItem & {
  defaultItem: boolean;
};

const createDefaultEmergencyItem = (
  cityId: string,
  id: string
): EditCitySiteEmergencyItem => ({
  city_id: cityId,
  id: id,
  text: '',
  site_ids: [],
  created_at: '',
  defaultItem: true,
});

const trimmedList = (list: CitySiteNews) =>
  _.filter(list, (x) => x.text.trim() !== '');

const getVolatileId = () => uuid();

const ManageEmergenciesModal: FC<ManageEmergenciesModalProps> = ({
  emergencies,
  onClose,
  cityId,
}) => {
  const [editList, setEditList] = useState<Array<EditCitySiteEmergencyItem>>([
    ...(emergencies as Array<EditCitySiteEmergencyItem>),
    createDefaultEmergencyItem(cityId, getVolatileId()),
  ]);
  const { sites } = useAppSelector((store) => ({
    sites: store.sites.data,
  }));
  const [updateEmergencies, getSites] = useActions([
    updateCityEmergencies,
    getOnlineSites,
  ]);

  useEffect(() => {
    getSites(cityId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSave = useCallback(() => {
    const validNews = trimmedList(editList);
    const updateList = _.map(validNews, (x) => ({
      id: x.id,
      text: x.text,
      site_ids: _.map(sites, (s) => s.id),
    })) as UpdateCityNewsItem[];

    updateEmergencies({
      cityId,
      newsList: updateList,
    }).then(() => {
      onClose(true);
    });
  }, [cityId, editList, updateEmergencies, onClose, sites]);

  const handleChangeItemValue = useCallback(
    (idx: number, text: string) => {
      const list = [...editList];
      list[idx].text = text;
      list[idx].defaultItem = false;

      if (
        !_.some(list, (x) => x.text.trim() === '') &&
        list.length < NEWS_LIST_MAX_SIZE
      ) {
        list.push(createDefaultEmergencyItem(cityId, getVolatileId()));
      }

      setEditList(list);
    },
    [editList, cityId]
  );

  const handleDeleteItem = useCallback(
    (index: number) => {
      const list = [...editList];
      list.splice(index, 1);

      if (
        !_.some(list, (x) => x.text.trim() === '') &&
        list.length < NEWS_LIST_MAX_SIZE
      ) {
        list.push(createDefaultEmergencyItem(cityId, getVolatileId()));
      }

      setEditList(list);
    },
    [editList, cityId]
  );

  const handleDragEnd = useCallback(
    (result: DropResult) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      const newQuotes = reorder(
        editList,
        result.source.index,
        result.destination.index
      );

      setEditList(newQuotes);
    },
    [editList]
  );

  return (
    <Modal size="xl">
      <ModalHeader>
        Emergenze {trimmedList(editList).length}/{NEWS_LIST_MAX_SIZE}
      </ModalHeader>
      <ModalBody>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Stack>
            <Droppable droppableId="news-list">
              {(dropProv) => (
                <Stack ref={dropProv.innerRef} {...dropProv.droppableProps}>
                  {_.map(editList, (n, idx) => (
                    <Draggable index={idx} draggableId={n.id} key={n.id}>
                      {(dragProv) => (
                        <TransitionContainer
                          ref={dragProv.innerRef}
                          {...dragProv.dragHandleProps}
                          {...dragProv.draggableProps}
                          key={n.id}
                          tabIndex={-1}
                        >
                          <EditRow
                            index={idx}
                            text={n.text}
                            onChangeValue={handleChangeItemValue}
                            onDeleteItem={handleDeleteItem}
                            defaultItem={n.defaultItem}
                          />
                        </TransitionContainer>
                      )}
                    </Draggable>
                  ))}
                  {dropProv.placeholder}
                </Stack>
              )}
            </Droppable>
          </Stack>
        </DragDropContext>
      </ModalBody>
      <ModalFooter>
        <Button variant="secondary" onClick={() => onClose(false)}>
          Annulla
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Pubblica
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ManageEmergenciesModal;
