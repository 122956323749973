import { Card, Text } from '@alexpireddu/map-ui';
import { FC } from 'react';
import styled from '@emotion/styled';

export interface LoaderProps {
  loading?: boolean;
  message?: string;
}

const LoaderOverlay = styled.div({
  position: 'absolute',
  top: '0',
  right: '0',
  bottom: '0',
  left: '0',
  margin: '0 auto',
  zIndex: '1010',
  backgroundColor: 'rgba(2, 2, 3, 0.6)',
});

const LoaderContent = styled(Card)({
  padding: '64px',
  position: 'absolute',
  opacity: '1',
  textAlign: 'center',
  width: 'calc(30vw)',
  height: 'calc(30vh)',
  top: 'calc(30vh)',
  left: 'calc(30vw)',
});

const Loader: FC<LoaderProps> = ({
  children,
  message = 'Caricamento in corso...',
  loading = false,
}) => (
  <>
    {loading && (
      <LoaderOverlay>
        <LoaderContent size="lg">
          <Text as="h2">{message}</Text>
        </LoaderContent>
      </LoaderOverlay>
    )}
    {children}
  </>
);

export default Loader;
