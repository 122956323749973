import { FC } from 'react';
import { Button, Stack, Text } from '@alexpireddu/map-ui';
import { Card } from '@alexpireddu/map-ui';
import CampaignCard from './CampaignCard';
import { useFormContext } from 'react-hook-form';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { WizardFormValues } from './WizardForm';

const StretchedButton = styled(Button)({
  paddingLeft: '2rem',
  paddingRight: '2rem',
});

const Recap: FC = () => {
  const navigate = useNavigate();
  const { getValues } = useFormContext<WizardFormValues>();
  const { name } = getValues();

  if (_.isNil(name)) {
    navigate('plan');
  }

  return (
    <Stack spacing={2} marginTop="2rem">
      <Text as="h2">Riepilogo pianificazione</Text>
      <Card size="lg">
        <Stack spacing={2}>
          <Stack direction="row" justifyContent={'space-between'}>
            <Text as="h3" fontWeight="bold" margin={0}>
              Comunicazione comunale
            </Text>
            <StretchedButton
              size="sm"
              variant="secondary"
              onClick={() => navigate(-1)}
            >
              Modifica
            </StretchedButton>
          </Stack>
          <Stack spacing={3}>
            <CampaignCard />
          </Stack>
        </Stack>
      </Card>
    </Stack>
  );
};

export default Recap;
