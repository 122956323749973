import { createContext, ElementType, ComponentType, FC } from 'react';

export type LazyImportInvokeFn = () => Promise<{
  default: ElementType | ComponentType | FC;
}>;

export interface OpenModalParams<T = any> {
  identifier?: string | number;
  lazyImportFn?: LazyImportInvokeFn;
  component?: ElementType | ComponentType | FC;
  additionalProps?: T;
}

export interface CloseModalParams {
  identifier: string | number;
}

export type OpenModalInvokeFn = <T>(args: OpenModalParams<T>) => void;

export type CloseModalInvokeFn = (args: CloseModalParams) => void;

export type ModalProviderContextType = {
  openModal: OpenModalInvokeFn;
  closeModal: CloseModalInvokeFn;
};

export const ModalProviderContext = createContext<ModalProviderContextType>({
  openModal: () => {},
  closeModal: () => {},
});
