import styled from '@emotion/styled';
import { FC, HTMLAttributes } from 'react';
import { colors } from '../../theming/theme';

export interface LogoProps extends HTMLAttributes<SVGElement> {
  className?: string;
  clickable?: boolean;
}

const LogoSvg = styled.svg<LogoProps>(({ clickable = false }) => ({
  fill: colors.text400,
  color: colors.text400,
  height: '34.3px',
  width: 'auto',
  ...(clickable && { cursor: 'pointer' }),
}));

const Logo: FC<LogoProps> = ({ className, ...others }) => (
  <LogoSvg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 620.8 141.7"
    xmlSpace="preserve"
    {...others}
  >
    <defs>
      <linearGradient
        id="logo_gradient"
        gradientTransform="rotate(-45)"
        x1="-70%"
        y1="0%"
        x2="70%"
        y2="0%"
      >
        <stop offset={0} stopColor="#ff1a90" stopOpacity={1} />
        <stop offset={100} stopColor="#00bbfa" stopOpacity={1} />
      </linearGradient>
    </defs>
    <g>
      <path
        d="M320.9,6.6c6.3,0,12.3,0,18.5,0c0,10.7,0,21.2,0,32c1.2-0.5,2-0.8,2.8-1.2
c16.5-7,35.5-0.5,44.3,15c7.1,12.5,5.6,29-4.1,40c-10,11.4-26.1,15.3-40,9.2c-11.9-5.2-18.7-14.4-21-27.1c-0.4-2-0.4-4.1-0.4-6.2
c0-19.8,0-39.6,0-59.4C320.9,8.3,320.9,7.6,320.9,6.6z M356,86.3c9.8-0.2,16.6-8.1,16.6-16.6c0.1-8.7-6.7-16.8-16.6-16.8
c-10.4,0-16.5,8.5-16.7,16.4C339.2,77,345.3,86,356,86.3z"
      />
      <path
        d="M190.2,100.9c0,10.4,0,20.9,0,31.5c-6.2,0-12.2,0-18.4,0c0-0.7-0.1-1.4-0.1-2.1
c0-20.2-0.1-40.5,0-60.7c0.1-17.2,11.8-31,27.9-34.2c20.4-4.1,38.6,9.5,41.6,28.4c1.8,11.4-1.2,21.5-9.1,29.9
c-7.4,7.9-16.8,11.4-27.7,10.7c-4.2-0.2-8.1-1.2-11.9-2.9c-0.5-0.2-1.1-0.4-1.6-0.6C190.7,100.8,190.6,100.9,190.2,100.9z
M190.2,69.6c-0.4,7.8,6.2,16.6,16.4,16.6c10.3,0.1,16.8-8.3,16.8-16.6c0-8.8-6.7-16.7-16.6-16.8C196.3,52.9,189.8,61.7,190.2,69.6z
"
      />
      <path
        d="M620.8,104.4c-6.2,0-12,0-18,0c-0.1-1.3-0.2-2.4-0.3-3.9c-1,0.4-1.8,0.7-2.6,1.1
c-13.1,5.2-25.3,3.7-36.3-5.1c-7.4-6-11.5-13.9-12.4-23.5c-1-10.8,2.2-20.2,9.7-27.8c8.3-8.4,18.5-11.7,30.2-10.1
c7.4,1,13.7,4.2,19,9.3c5.5,5.3,8.9,11.8,10.2,19.3c0.4,2.2,0.5,4.4,0.5,6.6c0,10.5,0,20.9,0,31.4
C620.8,102.5,620.8,103.3,620.8,104.4z M585.9,86.3c9.8-0.3,16.7-7.7,16.7-16.8c0-9.4-7.4-16.7-16.7-16.6
c-8.8,0.1-16.5,6.5-16.5,16.6C569.2,79.1,576.5,85.9,585.9,86.3z"
      />
      <path
        d="M457.7,93.4c2.7-4.3,5.2-8.6,7.9-12.9c1.8,1.2,3.4,2.3,5.1,3.4c3.3,2,6.8,3.9,10.7,4.5
c3.4,0.5,6.8,0.5,10.1-0.5c1.2-0.4,2.1-1.1,2.3-2.2c0.3-1.2-0.4-2.2-1.4-2.9c-1.7-1.3-3.7-2.1-5.7-2.8c-4.8-1.5-9.7-2.9-14.4-4.7
c-4.5-1.8-8.3-4.7-10.5-9.2c-3.7-7.7-2.5-15,2.4-21.7c3.9-5.3,9.4-7.8,15.8-8.7c7.8-1.1,15.3-0.2,22.5,3c3.9,1.7,9.1,5.4,11.3,8.2
c-2.6,4.2-5.1,8.4-7.8,12.8c-0.6-0.4-1.1-0.7-1.6-1c-2.9-1.7-5.8-3.6-8.8-5.1c-4.8-2.4-9.9-2.8-15.2-1.4c-1.2,0.3-2.3,0.8-2.6,2.2
c-0.3,1.4,0.3,2.6,1.5,3.2c1.7,0.9,3.4,1.8,5.2,2.5c4.9,1.7,10,3,14.8,4.8c4.7,1.8,8.5,5,10.6,9.7c3,6.7,2.3,13.2-1.4,19.4
c-3.5,5.9-9,9.2-15.7,10.4c-12.9,2.3-24.4-0.9-34.2-9.7C458.5,94.3,458.2,93.9,457.7,93.4z"
      />
      <path
        d="M293.6,35.8c5.9,0,11.5,0,17.3,0c0,0.9,0,1.6,0,2.4c0,12,0,24.1,0,36.1
c0,8.3-2.7,15.5-8.4,21.5c-5,5.3-11.3,8.3-18.6,9.1c-9.2,1-17.3-1.7-24.1-8c-5-4.6-8-10.3-9.1-17c-0.3-2-0.5-4.1-0.5-6.1
c0-12,0-23.9,0-35.9c0-0.6,0.1-1.3,0.1-2c5.7,0,11.3,0,17.1,0c0,0.8,0.1,1.6,0.1,2.4c0,12.5,0.2,24.9-0.1,37.4
c-0.1,6.2,4.3,13.2,12.7,13.6c4.9,0.2,8.9-2.1,11.5-6.4c1.3-2.1,1.8-4.4,1.8-6.9c-0.1-12.5,0-25.1,0-37.6
C293.6,37.5,293.6,36.7,293.6,35.8z"
      />
      <path
        d="M540.5,6.6c0,5,0,9.6,0,14.6c2.6,0.3,5,0.1,7.5,0.2c2.5,0,5,0,7.7,0c0.1,5.7,0,11.2,0,17
c-5.1,0-10,0-15.2,0c0,22.2,0,44.2,0,66.1c-1.9,0.5-15.9,0.6-18.2,0.1c0-32.6,0-65.2,0-98C528.3,6.6,534.3,6.6,540.5,6.6z"
      />
      <path
        d="M399.9,6.6c6.1,0,12,0,18.1,0c0,32.8,0,65.4,0,98.2c-6.1,0-12,0-18,0
C399.5,103.2,399.4,9.4,399.9,6.6z"
      />
      <path
        d="M429.2,34.8c5.9,0,11.8,0,17.5,0c0.5,1.6,0.6,67.2,0.2,69.8c-1.5,0.4-14.9,0.6-17.6,0.2
C428.9,103.2,428.8,37.8,429.2,34.8z"
      />
      <path
        d="M427.3,15.6c0-5.6,4.4-10.8,10.7-10.7c6.9,0.1,10.6,5.6,10.5,10.6c-0.1,6-4.7,10.7-10.7,10.7
C432.1,26.2,427.3,21.5,427.3,15.6z"
      />
      <path
        fill="url(#logo_gradient)"
        d="M0,70.8C0.1,31.7,31.6,0.1,70.9,0c39.1,0.1,70.7,31.6,70.8,71c-0.2,39.7-32.2,70.8-70.9,70.8
C31.7,141.7,0,110.1,0,70.8z M80.9,69.8c-0.1-0.2-0.3-0.4-0.5-0.6C70.7,59.6,61,49.9,51.4,40.2c-0.1-0.1-0.2-0.2-0.3-0.3
c-0.1-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.4,0-0.5,0c-2.5,0-4.9,0-7.4,0c-3.2,0-6.4,0-9.6,0c-0.2,0-0.5,0-0.7,0c0,0,0,0,0,0
c0,0,0,0.1,0,0.1c0.2,0.2,0.4,0.4,0.6,0.6c9.5,9.5,19.1,19.1,28.6,28.6c0.2,0.2,0.4,0.4,0.5,0.5c0.1,0.1,0.1,0.2,0,0.4
c-0.2,0.2-0.4,0.4-0.5,0.5c-9.6,9.6-19.1,19.1-28.7,28.7c-0.2,0.2-0.4,0.3-0.5,0.6c0,0.1,0.1,0.1,0.8,0.1c5.7,0,11.4,0,17,0
c0.9,0,0.7,0.1,1.4-0.6c9.6-9.6,19.2-19.2,28.8-28.8c0.2-0.2,0.3-0.3,0.5-0.5C80.8,70,80.9,69.9,80.9,69.8z M87,39.7
c-0.3,0-0.5,0-0.8,0c-4.2,0-8.4,0-12.6,0c-1.5,0-3,0-4.5,0c-0.2,0-0.4,0-0.5,0c0,0,0,0,0,0c0,0-0.1,0.1,0,0.1
c0.2,0.2,0.3,0.4,0.5,0.5c9.6,9.6,19.1,19.1,28.7,28.7c0.2,0.2,0.4,0.4,0.5,0.5c0.1,0.1,0.1,0.1,0.1,0.3c-0.2,0.2-0.3,0.3-0.5,0.5
C88.2,80,78.6,89.6,69.1,99.1c-0.2,0.2-0.3,0.3-0.5,0.5c0,0-0.1,0.1-0.1,0.2c0,0,0,0.1,0,0.1c0,0,0,0,0.1,0c0.2,0,0.5,0,0.7,0
c5.7,0,11.4,0,17.1,0c0.9,0,0.7,0.1,1.3-0.5c5-5,10-10,15-15c1.1-1.1,2.1-2.1,3.2-3.2c3.7-3.7,7.4-7.4,11-11
c0.1-0.1,0.2-0.2,0.3-0.3c0,0,0-0.1,0-0.1c0,0,0-0.1-0.1-0.1c-9.9-9.9-19.9-19.9-29.8-29.8C87.2,39.8,87.1,39.8,87,39.7z"
      />
    </g>
  </LogoSvg>
);

export default Logo;
