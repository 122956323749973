import _ from 'lodash';
import { FC, useState, useCallback } from 'react';
import {
  CloseModalParams,
  CloseModalInvokeFn,
  ModalProviderContext,
  OpenModalParams,
  OpenModalInvokeFn,
} from './ModalProviderContext';

export interface ModalComponent<T> {
  id: string | number;
  component: any;
  additionalProps?: T;
}

export const ModalProvider: FC = ({ children }) => {
  const [loadedModals, setLoadedModals] = useState<ModalComponent<any>[]>([]);

  const handleOnClose = useCallback(
    (identifier: string | number, additionalProps?: any, ...rest: any[]) => {
      setLoadedModals((prevValue) =>
        _.filter(prevValue, (x) => x.id !== identifier)
      );

      if (!_.isNil(additionalProps)) {
        const { onClose } = additionalProps;
        if (_.isFunction(onClose)) {
          onClose(...rest);
        }
      }
    },
    [setLoadedModals]
  );

  const handleModalOpening: OpenModalInvokeFn = useCallback(
    ({
      identifier,
      lazyImportFn,
      additionalProps,
      component,
    }: OpenModalParams) => {
      if (_.isFunction(lazyImportFn)) {
        lazyImportFn().then((module: any) => {
          setLoadedModals((prevValue) => [
            ...prevValue,
            {
              id: !_.isNil(identifier) ? identifier : loadedModals.length + 1,
              component: module.default ? module.default : module,
              additionalProps,
            },
          ]);
        });
      }

      if (!_.isNil(component)) {
        setLoadedModals((prevValue) => [
          ...prevValue,
          {
            id: !_.isNil(identifier) ? identifier : loadedModals.length + 1,
            component,
            additionalProps,
          },
        ]);
      }
    },
    [loadedModals.length]
  );

  const handleModalClosing: CloseModalInvokeFn = useCallback(
    ({ identifier }: CloseModalParams) => {
      setLoadedModals((prevValue) =>
        _.filter(prevValue, (x) => x.id !== identifier)
      );
    },
    [setLoadedModals]
  );

  return (
    <ModalProviderContext.Provider
      value={{ openModal: handleModalOpening, closeModal: handleModalClosing }}
    >
      {children}
      {loadedModals.map((m) => {
        const Component = m.component;

        return (
          <Component
            key={m.id}
            {...m.additionalProps}
            onClose={(...params: any[]) =>
              handleOnClose(m.id, m.additionalProps, ...params)
            }
          />
        );
      })}
    </ModalProviderContext.Provider>
  );
};
