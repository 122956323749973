import { PublistaAction } from './commonTypes';

export const actionFactory = <T = any>(
  type: string,
  payload?: T,
  globalCatch: boolean = true
): PublistaAction<T> => ({
  type,
  payload,
  meta: {
    timeStamp: new Date().toISOString(),
    globalCatch,
  },
});
