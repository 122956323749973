import { FC, SVGAttributes } from 'react';

const AccessDeniedIcon: FC<SVGAttributes<SVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-47 170.9 500.1 500"
    height="48"
    width="48"
    {...props}
  >
    <circle style={{ fill: '#4F5666' }} cx="203.1" cy="420.9" r="250" />
    <rect
      x="50"
      y="387.5"
      style={{ fill: '#FFFFFF' }}
      width="306"
      height="66.8"
    />
  </svg>
);

export default AccessDeniedIcon;
