export const DEV_BACKEND_URI = 'http://localhost:8080';

export const TEST_BACKEND_URI = 'https://api.qa.publista.it';

export const PROD_BACKEND_URI = 'https://api.io.publista.it';

export const DEV_LEDWALL_URI = 'https://ledwall.publista.it';

export const TEST_LEDWALL_URI = 'https://ledwall-qa.publista.it';

export const PROD_LEDWALL_URI = 'https://ledwall.publista.it';

export const DEV_HOMEPAGE_URI = 'https://localhost:3005';

export const TEST_HOMEPAGE_URI = 'https://booking-qa.publista.it';

export const PROD_HOMEPAGE_URI = 'https://booking.publista.it';

export const DEV_AUTH_REDIRECT_URI =
  'http://localhost:3000/accedi/redirect?address=' + window.location.href;

export const TEST_AUTH_REDIRECT_URI =
  'https://login.qa.publista.it/accedi/redirect?address=' +
  window.location.href;

export const PROD_AUTH_REDIRECT_URI =
  'https://login.io.publista.it/accedi/redirect?address=' +
  window.location.href;
