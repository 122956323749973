import axios, { AxiosRequestConfig } from 'axios';
import { ENV_VARIABLES, getBackendBaseUri } from './environment';

const commonApiHeaderOptions = {
  'Content-Type': 'application/json',
  ...(ENV_VARIABLES.REACT_APP_X_API_KEY && {
    'X-API-KEY': ENV_VARIABLES.REACT_APP_X_API_KEY,
  }),
};

export const get = <T = void>(
  relativeUrl: string,
  parameters: Object = {},
  config?: AxiosRequestConfig<void>
) =>
  axios.get<T>(relativeUrl, {
    baseURL: getBackendBaseUri(),
    params: parameters,
    withCredentials: true,
    headers: {
      ...commonApiHeaderOptions,
    },
    ...config,
  });

export const post = <T = void>(
  relativeUrl: string,
  data: Object,
  parameters: Object = {},
  config?: AxiosRequestConfig<Object>
) =>
  axios.post<T>(relativeUrl, data, {
    baseURL: getBackendBaseUri(),
    params: parameters,
    withCredentials: true,
    headers: {
      ...commonApiHeaderOptions,
    },
    ...config,
  });

export const put = <T = void>(
  relativeUrl: string,
  data: Object,
  config?: AxiosRequestConfig<Object>
) =>
  axios.put<T>(relativeUrl, data, {
    baseURL: getBackendBaseUri(),
    withCredentials: true,
    headers: {
      ...commonApiHeaderOptions,
    },
    ...config,
  });

export const remove = <T = void>(
  relativeUrl: string,
  config?: AxiosRequestConfig<void>
) =>
  axios.delete<T>(relativeUrl, {
    baseURL: getBackendBaseUri(),
    withCredentials: true,
    headers: {
      ...commonApiHeaderOptions,
    },
    ...config,
  });
