import { Box, Flex } from '@alexpireddu/map-ui';
import styled from '@emotion/styled';
import { FC, ReactNode } from 'react';
import { colors } from '../../theming/theme';

export interface FooterProps {
  className?: string;
  leftContent?: ReactNode;
  middleContent?: ReactNode;
  rightContent?: ReactNode;
}

const FooterContainer = styled(Flex)({
  backgroundColor: colors.white,
  borderTop: 'solid 1px ' + colors.grey300,
  padding: '16px 42px',
});

const Footer: FC<FooterProps> = ({
  leftContent,
  middleContent,
  rightContent,
  ...rest
}) => (
  <FooterContainer {...rest}>
    <Box className="pa__footer_left">{leftContent}</Box>
    <Box className="pa__footer_middle" flexGrow={4} flexShrink={3}>
      {middleContent}
    </Box>
    <Box className="pa__footer_right">{rightContent}</Box>
  </FooterContainer>
);

export default Footer;
