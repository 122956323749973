import { FC, useEffect } from 'react';
import _ from 'lodash';
import { useAction, useAppSelector } from '../../hooks';
import { getCities } from '../../actions';
import { Outlet } from 'react-router-dom';

const CitiesBootstrapper: FC = () => {
  const { cities } = useAppSelector((store) => ({
    cities: store.cities,
  }));
  const citiesGet = useAction(getCities);

  useEffect(() => {
    const { fetching, fetched, error } = cities;
    if (!fetched && !fetching && _.isNil(error)) {
      citiesGet();
    }
  }, [cities, citiesGet]);

  const { fetching: citiesFetching, fetched: citiesFetched } = cities;

  if (citiesFetching || !citiesFetched) {
    return null;
  }

  return <Outlet />;
};

export default CitiesBootstrapper;
