import { ActionCreator } from 'redux';
import {
  getSubscriberInfoV1,
  getSubscriberRolesV1,
  refreshTokenV1,
  subscriberLogoutV1,
  UserInfo,
} from '../apis/userApi';
import { actionFactory } from '../infrastructure/actionFactory';
import { PublistaAction } from '../infrastructure/commonTypes';

export const getSubscriberRoles = () =>
  actionFactory('SUBSCRIBER_ROLES_GET', getSubscriberRolesV1());

export const getSubscriberInfo: ActionCreator<PublistaAction> = () =>
  actionFactory('SUBSCRIBER_INFO_GET', getSubscriberInfoV1());

export const setCurrentUser: ActionCreator<PublistaAction> = (user: UserInfo) =>
  actionFactory('SUBSCRIBER_INFO_SET', user);

export const refreshSubscriberToken: ActionCreator<PublistaAction> = () =>
  actionFactory('SUBSCRIBER_TOKEN_REFRESH', refreshTokenV1());

export const subscriberLogout: ActionCreator<PublistaAction> = () =>
  actionFactory('SUBSCRIBER_LOGOUT', subscriberLogoutV1());
