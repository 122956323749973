import {
  Button,
  Card,
  FormControl,
  Hint,
  Input,
  Stack,
  Text,
} from '@alexpireddu/map-ui';
import styled from '@emotion/styled';
import _ from 'lodash';
import { FC, useState, useEffect, useCallback, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { getOnlineSites } from '../../actions';
import { getInstitutionalCampaignsApproved } from '../../actions/videoActions';
import { CitySites } from '../../apis/sitesApi';
import { CityAdminVideoCampaigns } from '../../apis/videoApi';
import { DateRangePicker } from '../../components';
import { useAction, useCityParam } from '../../hooks';
import {
  addYears,
  formatDateISOShort,
  formatDateLocale,
} from '../../utils/dateHelper';
import { openGoogleMapsPin } from '../../utils/mapsUtils';
import NextCampaigns from './NextCampaigns';

const DateInput = styled(Input)({
  cursor: 'pointer',
});

export type GeneralInformationValues = {
  name: string;
  fromDate: string;
  toDate: string;
};

export const generalInformationInitialFormValues = {
  name: undefined,
  fromDate: undefined,
  toDate: undefined,
};

const GeneralInformation: FC = () => {
  const { currentCityId } = useCityParam();
  const [sites, setSites] = useState<CitySites>([]);
  const videoSitesGet = useAction(getOnlineSites);

  const loadSites = useCallback(() => {
    videoSitesGet(currentCityId).then((resp: any) => {
      setSites(resp.value.data);
    });
  }, [videoSitesGet, currentCityId]);

  useEffect(() => {
    if (!_.isNil(currentCityId)) {
      loadSites();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [nextCampaigns, setNextCampaigns] = useState<CityAdminVideoCampaigns>(
    []
  );
  const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false);
  const { register, formState, getValues, setValue } =
    useFormContext<GeneralInformationValues>();
  const {
    errors: { name: nameFieldError, fromDate: fromDateError },
  } = formState;
  useWatch({ name: 'name' });
  const { name, fromDate, toDate } = getValues();

  const getNextInstitutionalCampaigns = useAction(
    getInstitutionalCampaignsApproved
  );

  useEffect(() => {
    getNextInstitutionalCampaigns(
      currentCityId,
      formatDateISOShort(new Date().toISOString()),
      formatDateISOShort(addYears(new Date(), 1).toISOString())
    ).then((res: any) => {
      setNextCampaigns(res.value.data);
    });
  }, [currentCityId, getNextInstitutionalCampaigns]);

  const handleDateRangeSelection = (from: Date, to: Date) => {
    setValue('fromDate', formatDateISOShort(from.toISOString()) || '');
    setValue('toDate', formatDateISOShort(to!.toISOString()) || '');
  };

  const selectedSite = useMemo(() => _.head(sites), [sites]);

  return (
    <Stack spacing={4}>
      <Stack direction="row" justifyContent={'space-between'} marginTop={24}>
        <Text as="h2">Comunicazione comunale</Text>
        <Button
          variant="accent"
          icon={'map-marker-alt'}
          onClick={() =>
            openGoogleMapsPin(selectedSite?.latitude, selectedSite?.longitude)
          }
        >
          Mappa impianto
        </Button>
      </Stack>
      <Card size="lg">
        <Stack spacing={2}>
          <Text as="h3" fontWeight={'bold'}>
            Scegli nome comunicazione
          </Text>
          <FormControl isInvalid={!_.isNil(nameFieldError)}>
            <Input
              placeholder="Es. Fiera del riso"
              {...register('name', { required: 'Il campo è obbligatorio' })}
            />
            <Hint invalidMessage={nameFieldError?.message} />
            <Text color="grey" textAlign="right" fontSize={12}>
              {name?.length ?? 0} / 50
            </Text>
          </FormControl>
        </Stack>
      </Card>
      <Card size="lg">
        <Stack spacing={2}>
          <Text as="h3" fontWeight={'bold'}>
            Scegli periodo
          </Text>
          <Stack direction="row" spacing={2} alignItems="flex-start">
            <Button onClick={() => setDatePickerOpen(true)}>
              Seleziona periodo
            </Button>
            <Stack width={220}>
              <DateInput
                readOnly
                isInvalid={!_.isNil(fromDateError)}
                placeholder="Data inizio - Data fine"
                onClick={() => setDatePickerOpen(true)}
                value={
                  (formatDateLocale(fromDate)! || 'gg/mm/aaaa') +
                  ' - ' +
                  (formatDateLocale(toDate)! || 'gg/mm/aaaa')
                }
              />
              {/* <Hint
                invalidMessage={'Il campo è obbligatorio'}
                isInvalid={_.isNil(fromDate)}
              /> */}
            </Stack>
          </Stack>
          <NextCampaigns nextCampaigns={nextCampaigns} />

          {datePickerOpen && (
            <DateRangePicker
              onClose={() => setDatePickerOpen(false)}
              onConfirm={handleDateRangeSelection}
              minDate={new Date()}
            />
          )}
        </Stack>
      </Card>
    </Stack>
  );
};

export default GeneralInformation;
