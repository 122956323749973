import { configureStore } from '@reduxjs/toolkit';
import { combinedReducer } from '../reducers/createReducer';
import promise from 'redux-promise-middleware';
import authMiddleware from '../middlewares/authMiddleware';
import exceptionsMiddleware from '../middlewares/exceptionsMiddleware';

export const store = configureStore({
  reducer: combinedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActionPaths: ['payload'],
      },
    }).concat(promise, authMiddleware, exceptionsMiddleware),
});

// eslint-disable-next-line no-undef
export type RootStateType = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
