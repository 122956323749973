import { Cities, CityUsersInfo } from '../apis/cityApis';
import { CitySiteNews } from '../apis/newsApis';
import { CitySites } from '../apis/sitesApi';
import { ProvinceInfo, SiteFormatInfo } from '../apis/supportApi';
import { UserInfo } from '../apis/userApi';
import { RootStateType } from './configureStore';

export interface ClientError {
  statusCode?: number;
  statusMessage?: string;
  message: string;
  stackMessage?: string;
}

export type BaseInitialState<T> = {
  data: T;
  error: ClientError | null;
  fetched: boolean;
  fetching: boolean;
};

export const BASE_INITIAL_STATE: BaseInitialState<Object> = {
  data: {},
  error: null,
  fetched: false,
  fetching: false,
};

export type UserInitialStateType = BaseInitialState<UserInfo>;

export const userInitialState: UserInitialStateType = {
  ...BASE_INITIAL_STATE,
  data: {
    name: 'Caricamento in corso...',
    surname: undefined,
    email: '----',
    id: null,
    sso_user: false,
    roles: [],
  },
};

export type CitiesInitialStateType = BaseInitialState<Cities>;

export const citiesInitialState: CitiesInitialStateType = {
  ...BASE_INITIAL_STATE,
  data: [],
};

export type NewsInitialStateType = BaseInitialState<CitySiteNews>;

export const newsInitialState: NewsInitialStateType = {
  ...BASE_INITIAL_STATE,
  data: [],
};

export type EmergenciesInitialStateType = BaseInitialState<CitySiteNews>;

export const emergenciesInitialState: EmergenciesInitialStateType = {
  ...BASE_INITIAL_STATE,
  data: [],
};

export type SitesInitialStateType = BaseInitialState<CitySites>;

export const sitesInitialState: SitesInitialStateType = {
  ...BASE_INITIAL_STATE,
  data: [],
};

export type EmployeesInitialStateType = BaseInitialState<CityUsersInfo>;

export const employeesInitialState: EmployeesInitialStateType = {
  ...BASE_INITIAL_STATE,
  data: [],
};

export type ProvincesInitialStateType = BaseInitialState<Array<ProvinceInfo>>;

export type SiteFormatsInitialStateType = BaseInitialState<
  Array<SiteFormatInfo>
>;

export type SupportInitialStateTypes = {
  provinces: ProvincesInitialStateType;
  siteFormats: SiteFormatsInitialStateType;
};

export const supportInitialState: SupportInitialStateTypes = {
  provinces: { ...BASE_INITIAL_STATE, data: [] },
  siteFormats: { ...BASE_INITIAL_STATE, data: [] },
};

export const initialState: RootStateType = {
  cities: citiesInitialState,
  emergencies: emergenciesInitialState,
  employees: employeesInitialState,
  news: newsInitialState,
  sites: sitesInitialState,
  support: supportInitialState,
  user: userInitialState,
};
