import { Box, Button, Flex } from '@alexpireddu/map-ui';
import styled from '@emotion/styled';
import { Navigate } from 'react-router-dom';
import { FC } from 'react';
import { useCheckAuthorization } from '../../hooks';
import { colors } from '../../theming/theme';
import Header from '../Header';
import { AccessDeniedIcon } from '../Icons';
import Logo from '../Logo';
import { getHomepageUri } from '../../infrastructure/environment';

const MainWrapper = styled.div({
  display: 'inline-block',
  margin: '0 auto',
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '16px',
});

const DeniedIconContainer = styled.div({
  fill: colors.grey200,
  width: '200px',
  height: '200px',
  margin: '0 auto',
});

const Message = styled.h2({
  textAlign: 'center',
  marginTop: '32px',
  fontWeight: 'lighter',
});

const UppercaseButton = styled(Button)({
  textTransform: 'uppercase',
  paddingLeft: '24px',
  paddingRight: '24px',
});

const AccessDeniedPage: FC = () => {
  const auth = useCheckAuthorization();

  const returnHome = () => {
    window.location.href = getHomepageUri();
  };

  if (auth) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Header leftContent={<Logo />}></Header>
      <MainWrapper>
        <DeniedIconContainer>
          <AccessDeniedIcon width="200" height="200" />
        </DeniedIconContainer>
        <Message>Accesso negato a questa pagina.</Message>
        <Flex mt="32px" justifyContent="center">
          <Box>
            <UppercaseButton variant="primary" onClick={returnHome}>
              Torna a Publista.it
            </UppercaseButton>
          </Box>
        </Flex>
      </MainWrapper>
    </>
  );
};

export default AccessDeniedPage;
