import { get } from '../infrastructure/webRequest';
import { ProvinceInfo } from './supportApi';

export interface CityService {
  name: string;
  active: boolean;
}

export type CityServices = object;

export interface CityProvince {
  id: string;
  name: string;
}

export interface CityInfo {
  address: string;
  id: string;
  city_class: number;
  logo: string;
  mail: string;
  name: string;
  pec: string;
  phone: string;
  postcode: string;
  province: ProvinceInfo;
  services: CityServices;
}

export type Cities = Array<CityInfo>;

export interface CityUserRole {
  role: string;
}

export type CityUserRoles = Array<CityUserRole>;

export interface CityUser {
  email: string;
  id: string;
  name: string;
  surname: string;
}

export interface CityUserInfo {
  user: CityUser;
  roles: CityUserRoles;
}

export type CityUsersInfo = Array<CityUserInfo>;

export const getCitiesV1 = () => get<Cities>('/v1/cityadmin/cities');

export const getCityUsersV1 = (cityId: string) =>
  get<CityUsersInfo>(`/v1/cityadmin/city/${cityId}/users`);

export const getCityLogoV1 = (cityId: string) =>
  get<string>(`/v1/cityadmin/city/${cityId}/logo`, undefined, {
    responseType: 'arraybuffer',
    responseEncoding: 'base64',
  }).then((resp) => new Blob([resp.data]));
