import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useCheckAuthorization, useCheckMobile } from '../../hooks';
import Mobile from '../../areas/Mobile';

const CheckAuthorization: FC = () => {
  const auth = useCheckAuthorization();
  const isMobile = useCheckMobile();

  if (!auth) {
    return <Navigate to="unauthorized" />;
  }

  if (isMobile) {
    return <Mobile />;
  }

  return <Outlet />;
};

export default CheckAuthorization;
