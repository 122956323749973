import { Avatar, Box, Flex } from '@alexpireddu/map-ui';
import styled from '@emotion/styled';
import { FC } from 'react';
import { UserInfo } from '../../apis/userApi';
import { colors } from '../../theming/theme';

export interface UserBoxProps {
  user: UserInfo;
}

const NameText = styled.h4({
  fontSize: '14px',
  margin: '0',
  padding: '0',
  color: colors.text300,
  lineHeight: '20px',
  fontWeight: '600',
});

const MailText = styled.p({
  fontSize: '12px',
  color: colors.text300,
  margin: '0',
  padding: '0',
  lineHeight: '22px',
});

const UserBox: FC<UserBoxProps> = ({ user }) => (
  <Flex mb={20} alignItems="center">
    <Box mr={16}>
      <Avatar name={user.name + ' ' + user.surname} />
    </Box>
    <Box flexGrow={4}>
      <NameText>{user.name + ' ' + user.surname}</NameText>
      <MailText>{user.email}</MailText>
    </Box>
  </Flex>
);

export default UserBox;
