import { Box, Card } from '@alexpireddu/map-ui';
import { FC } from 'react';
import CitySelect from '../CitySelect';
import styled from '@emotion/styled';
import { colors } from '../../theming/theme';
import UserBox from './UserBox';
import MenuLinks from './MenuLinks';
import { useAppSelector } from '../../hooks';

const ContentContainer = styled(Card)({
  maxWidth: '264px',
  width: '264px',
});

const Divider = styled.div({
  marginLeft: '-24px',
  marginRight: '-24px',
  borderBottom: 'solid 1px ' + colors.grey300,
  marginTop: '24px',
  marginBottom: '24px',
});

const Content: FC = () => {
  const { user } = useAppSelector((store) => ({
    user: store.user.data,
  }));

  return (
    <ContentContainer size="lg">
      <Box mb={12}>
        <CitySelect isFullWidth size="md" />
      </Box>
      <UserBox user={user} />
      <Divider />
      <MenuLinks />
    </ContentContainer>
  );
};

export default Content;
