import {
  getCityEmergenciesV1,
  getCityNewsV1,
  resetCityEmergenciesV1,
  updateCityEmergenciesV1,
  UpdateCityNewsRequest,
  updateCityNewsV1,
} from '../apis/newsApis';
import { actionFactory } from '../infrastructure/actionFactory';

export const getCityNews = (cityId: string) =>
  actionFactory('CITY_NEWS_GET', getCityNewsV1(cityId));

export const updateCityNews = (request: UpdateCityNewsRequest) =>
  actionFactory('CITY_NEWS_UPDATE', updateCityNewsV1(request));

export const getCityEmergencies = (cityId: string) =>
  actionFactory('CITY_EMERGENCIES_GET', getCityEmergenciesV1(cityId));

export const updateCityEmergencies = (request: UpdateCityNewsRequest) =>
  actionFactory('CITY_EMERGENCIES_UPDATE', updateCityEmergenciesV1(request));

export const resetCityEmergencies = (cityId: string) =>
  actionFactory('CITY_EMERGENCIES_RESET', resetCityEmergenciesV1(cityId));
