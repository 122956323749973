import { get } from '../infrastructure/webRequest';

export const getResizedImageV1 = (cityId: string, imageId: string) => {
  return get<string>(
    `/v1/cityadmin/city/${cityId}/image/${imageId}/resized`,
    undefined,
    {
      responseType: 'arraybuffer',
      responseEncoding: 'base64',
    }
  ).then((resp) => new Blob([resp.data]));
};

export const getOriginalImageV1 = (cityId: string, imageId: string) => {
  return get<string>(
    `/v1/cityadmin/city/${cityId}/image/${imageId}`,
    undefined,
    {
      responseType: 'arraybuffer',
      responseEncoding: 'base64',
    }
  ).then((resp) => new Blob([resp.data]));
};
