import { get } from '../infrastructure/webRequest';

export interface ProvinceInfo {
  code: string;
  id: string;
  name: string;
  region_id: string;
}

export type Provinces = Array<ProvinceInfo>;

export interface SiteFormatInfo {
  height: number;
  id: string;
  sheet_number: number;
  width: number;
}

export type SiteFormats = Array<SiteFormatInfo>;

export const getSiteFormatsV1 = () => get<SiteFormats>('/v1/cityadmin/formats');

export const getProvinceInfoV1 = (id: string) =>
  get<ProvinceInfo>('/v1/province/info', { id });
