import { FC } from 'react';
import { Box, Stack } from '@alexpireddu/map-ui';
import GeneralInformation from './GeneralInformation';
import UploadingImage from './UploadingImage';

const PlanCommunication: FC = () => (
  <Stack spacing={4}>
    <GeneralInformation />
    <Box margin={'0 148px'} marginBottom={32}>
      <UploadingImage />
    </Box>
  </Stack>
);

export default PlanCommunication;
