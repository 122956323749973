import styled from '@emotion/styled';

interface BodyProps {
  footerShown: boolean;
  emergenciesBanner: boolean;
}

const calcHeight = (footer: boolean, emergencies: boolean) => {
  let totHeight = 96;

  if (footer) {
    totHeight += 78;
  }
  if (emergencies) {
    totHeight += 50;
  }

  return totHeight;
};

export const Body = styled.div<BodyProps>(
  ({ footerShown = false, emergenciesBanner = false }) => ({
    minHeight: `calc(100vh - calc(${calcHeight(
      footerShown,
      emergenciesBanner
    )}px))`,
    maxHeight: `calc(100vh - calc(${calcHeight(
      footerShown,
      emergenciesBanner
    )}px))`,
    height: `calc(100vh - calc(${calcHeight(
      footerShown,
      emergenciesBanner
    )}px))`,
    overflowY: 'auto',
    padding: '0 26px',
  })
);
