import { ActionCreator } from 'redux';
import { getProvinceInfoV1, getSiteFormatsV1 } from '../apis/supportApi';
import { actionFactory } from '../infrastructure/actionFactory';
import { PublistaAction } from '../infrastructure/commonTypes';

export const getProvinceInfo: ActionCreator<PublistaAction> = (id: string) =>
  actionFactory('PROVINCE_INFO_GET', getProvinceInfoV1(id));

export const getSiteFormats: ActionCreator<PublistaAction> = () =>
  actionFactory('SITE_FORMATS_GET', getSiteFormatsV1());
