import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { FC } from 'react';
import {
  DateRange as RangePicker,
  DateRangePickerProps as RangePickerProps,
  Range,
} from 'react-date-range';
import it from 'date-fns/locale/it';
import { colors } from '../../theming/theme';
import _ from 'lodash';

export interface DateRangePickerProps extends RangePickerProps {
  selectedRange?: Range;
}

const DateRangePicker: FC<DateRangePickerProps> = ({
  locale,
  dateDisplayFormat,
  startDatePlaceholder,
  endDatePlaceholder,
  editableDateInputs,
  color,
  selectedRange,
  ...others
}) => {
  const ranges = _.isNil(selectedRange) ? [] : [selectedRange];

  return (
    <RangePicker
      locale={it}
      dateDisplayFormat="dd/MM/yyyy"
      startDatePlaceholder="Data di inizio"
      endDatePlaceholder="Data di fine"
      editableDateInputs={false}
      color={colors.interaction200}
      ranges={ranges}
      {...others}
    />
  );
};

export default DateRangePicker;
