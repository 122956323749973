import _ from 'lodash';
import { FC } from 'react';
import { formatDayMonth } from '../../utils/dateHelper';
import { CityAdminVideoTimeSlotGrouped } from './TableRow';
import DailySlot from './DailySlot';

export interface TimeSlotProps {
  slot: CityAdminVideoTimeSlotGrouped;
}

const TimeSlot: FC<TimeSlotProps> = ({ slot }) => (
  <>
    <span style={{ textTransform: 'uppercase' }}>
      {formatDayMonth(slot.from_date)} - {formatDayMonth(slot.to_date)}
    </span>
    {_.map(slot.timeSlots, (s) => (
      <DailySlot dailySlot={s} key={s} />
    ))}
  </>
);

export default TimeSlot;
