import { FC } from 'react';
import { Provider } from 'react-redux';
import { store } from '../../store/configureStore';
import { FontFace, MapUiProvider } from '@alexpireddu/map-ui';
import { ModalProvider } from '../ModalProvider';
import { publistaTheme } from '../../theming/theme';

const MainUIProviders: FC = ({ children }) => (
  <Provider store={store}>
    <MapUiProvider theme={publistaTheme}>
      <ModalProvider>{children}</ModalProvider>
    </MapUiProvider>
    <FontFace
      fonts={[
        'https://fonts.googleapis.com/css2?family=Titillium+Web:wght@400;600&display=swap',
      ]}
    />
  </Provider>
);

export default MainUIProviders;
