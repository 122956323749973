import { padding } from '@alexpireddu/map-ui';

export const colors = {
  success300: '#5da862',
  success200: '#d6e9d7',
  danger300: '#be0000',
  danger200: '#da1d10',
  warning300: '#f1b000',
  interaction300: '#0094ca',
  interaction200: '#21c5fd',
  interaction100: '#83dfff',
  text400: '#020303',
  text300: '#263238',
  text200: '#37474f',
  text100: '#455a64',
  neutral500: '#546e7a',
  neutral400: '#607d8b',
  neutral300: '#78909c',
  neutral200: '#90a4ae',
  neutral100: '#b0bec5',
  grey200: '#eceff1',
  grey300: '#cfd8dc',
  white: '#ffffff',
  black: '#000000',
};

export const shadows = {
  base: '0 6px 24px 0 rgba(2, 3, 3, 0.16)',
};

export const publistaTheme = {
  global: {
    body: {
      h3: {
        margin: '0 0 8px 0',
      },
    },
  },
  alert: {
    warning: {
      color: colors.black,
    },
  },
  button: {
    xs: {
      ...padding(
        (theme: any) => theme.spacing['1'],
        (theme: any) => theme.spacing['3']
      ),
    },
    sm: {
      ...padding(
        (theme: any) => theme.spacing['2'],
        (theme: any) => theme.spacing['3']
      ),
    },
    md: {
      ...padding(
        (theme: any) => theme.spacing['3'],
        (theme: any) => theme.spacing['8']
      ),
    },
    lg: {
      ...padding(
        (theme: any) => theme.spacing['4'],
        (theme: any) => theme.spacing['3']
      ),
    },
    xl: {
      ...padding(
        (theme: any) => theme.spacing['5'],
        (theme: any) => theme.spacing['3']
      ),
    },
  },
  form: {
    hint: {
      defaultStyles: {
        marginTop: (theme: any) => theme.spacing[1],
      },
    },
  },
};
