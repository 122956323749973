import { Box, Button, Flex } from '@alexpireddu/map-ui';
import { FC, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { WizardFormValues } from '../../areas/Booking/WizardForm';
import Footer from '../Footer';

export interface BookingFooterProps {
  activeStep: number;
}

const BookingFooter: FC<BookingFooterProps> = ({ activeStep }) => {
  const navigate = useNavigate();
  const { trigger } = useFormContext<WizardFormValues>();

  const triggerValidation = useCallback(() => {
    trigger().then((isValid: boolean) => {
      if (isValid) {
        navigate('recap/');
      }
    });
  }, [navigate, trigger]);

  return (
    <Footer
      middleContent={
        <>
          <Box width={'100%'} margin="0 auto" maxWidth={1060} minWidth={800}>
            {activeStep === 0 && (
              <Flex justifyContent="space-between">
                <Box>
                  <Button
                    variant="secondary"
                    size="md"
                    onClick={() => {
                      navigate('/');
                    }}
                  >
                    Indietro
                  </Button>
                </Box>
                <Box>
                  <Button
                    variant="primary"
                    size="md"
                    onClick={triggerValidation}
                  >
                    Continua
                  </Button>
                </Box>
              </Flex>
            )}
            {activeStep === 1 && (
              <Flex justifyContent={'right'}>
                <Box>
                  <Button variant="primary" size="md" type="submit">
                    Pianifica
                  </Button>
                </Box>
              </Flex>
            )}
          </Box>
        </>
      }
    />
  );
};

export default BookingFooter;
