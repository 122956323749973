import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { CityRoutingParam } from '../infrastructure/commonTypes';
import { useAppSelector } from './storeHooks';
import { CityService } from '../enums';

export const useVideoService = () => {
  const { currentCityId } = useParams<Partial<CityRoutingParam>>();

  const selectedCity = useAppSelector((store) =>
    _.find(store.cities.data, (x) => x.id === currentCityId)
  );

  const videoService = selectedCity?.services as any;

  return _.isNil(videoService) ? false : videoService[CityService.Video];
};
