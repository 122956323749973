import {
  AdvancedTable,
  HeaderCell,
  TableBody,
  TableHeader,
  useAdvancedTable,
} from '@alexpireddu/map-ui';
import _ from 'lodash';
import { FC, useCallback, useEffect, useState } from 'react';
import {
  getCampaigns,
  getInstitutionalCampaigns,
} from '../../actions/videoActions';
import { VideoCampaignListResponse } from '../../apis/videoApi';
import { CampaignPurpose, CampaignState } from '../../enums';
import { useActions, useCityParam } from '../../hooks';
import NoResultsRow from './NoResultsRow';
import TableRow from './TableRow';

export interface CampaignsTableProps {
  filteredState: number;
  filteredPurpose: number;
  onReloadCampaigns: () => void;
}

const CampaignsTable: FC<CampaignsTableProps> = ({
  filteredState,
  filteredPurpose,
  onReloadCampaigns,
}) => {
  const { currentCityId } = useCityParam();
  const [campaignList, setCampaignList] = useState<VideoCampaignListResponse>();
  const [videoCampaignsGet, videoInstitutionalsGet] = useActions([
    getCampaigns,
    getInstitutionalCampaigns,
  ]);
  const advancedTableProps = useAdvancedTable({
    sortProperty: 'from_date',
    sortOrder: 'desc',
    totalCount: campaignList?.total || 0,
    pageSize: 5,
  });

  const { currentPage, pageSize, sortProperty, sortOrder } = advancedTableProps;

  const loadCampaigns = useCallback(() => {
    if (!_.isNil(currentCityId)) {
      if (filteredPurpose === CampaignPurpose.Business) {
        videoCampaignsGet(
          currentCityId,
          currentPage,
          pageSize,
          filteredState,
          sortProperty,
          sortOrder
        ).then((resp: any) => setCampaignList(resp.value.data));
      } else {
        videoInstitutionalsGet(
          currentCityId,
          currentPage,
          pageSize,
          filteredState,
          sortProperty,
          sortOrder
        ).then((resp: any) => setCampaignList(resp.value.data));
      }
    }
    onReloadCampaigns();
  }, [
    currentCityId,
    currentPage,
    pageSize,
    filteredPurpose,
    filteredState,
    videoCampaignsGet,
    videoInstitutionalsGet,
    onReloadCampaigns,
    sortOrder,
    sortProperty,
  ]);

  useEffect(() => {
    loadCampaigns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filteredState,
    filteredPurpose,
    currentCityId,
    currentPage,
    pageSize,
    sortOrder,
    sortProperty,
  ]);

  return (
    <AdvancedTable isFullWidth {...advancedTableProps}>
      <TableHeader>
        <tr>
          <HeaderCell fixedWidth={130}>Contenuto</HeaderCell>
          <HeaderCell bindingProperty="from_date">Periodo</HeaderCell>
          <HeaderCell bindingProperty="name">Nome campagna</HeaderCell>
          <HeaderCell>Richiedente</HeaderCell>
          {filteredPurpose === CampaignPurpose.Business && (
            <HeaderCell style={{ width: '300px' }}>Spazi</HeaderCell>
          )}
          <HeaderCell fixedWidth={180}>Prenotazione</HeaderCell>
          <HeaderCell fixedWidth={150}> Stato </HeaderCell>
          {filteredPurpose === CampaignPurpose.Istitutional &&
            filteredState !== CampaignState.Completed && (
              <HeaderCell fixedWidth={120}>&nbsp;</HeaderCell>
            )}
        </tr>
      </TableHeader>
      <TableBody>
        {_.map(campaignList?.results, (c) => (
          <TableRow
            key={c.id}
            campaign={c}
            purpose={filteredPurpose}
            onReloadCampaigns={loadCampaigns}
          />
        ))}
        {!_.some(campaignList?.results) && (
          <NoResultsRow
            colSpan={filteredPurpose === CampaignPurpose.Business ? 8 : 7}
          />
        )}
      </TableBody>
    </AdvancedTable>
  );
};

export default CampaignsTable;
