import { getLedwallBaseUri } from '../infrastructure/environment';

function isScreenVertical(screenId: string) {
  const validScreenIds = [
    '384f08e2-817c-47b8-b371-111d046ae8e0',
    '2f0f7e46-c4ea-4c4d-9357-a6c26a976a87',
  ];
  let isVertical = screenId && validScreenIds.includes(screenId);

  return Boolean(isVertical);
}

export const openLedwallPreview = (siteId: string) => {
  const ledwallUri = `${getLedwallBaseUri()}/${siteId}`;

  let popup;

  let isVertical = isScreenVertical(siteId);
  if (isVertical) {
    popup = window.open(
      ledwallUri,
      siteId,
      'width=270,height=480,titlebar=no,toolbar=no,directories=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,top=100'
    );
  } else {
    popup = window.open(
      ledwallUri,
      siteId,
      'width=512,height=384,titlebar=no,toolbar=no,directories=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,top=100'
    );
  }

  return popup;
};
