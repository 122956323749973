import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Box } from '@alexpireddu/map-ui';
import _ from 'lodash';
import { FC, ReactNode } from 'react';
import styled from '@emotion/styled';
import { colors } from '../../theming/theme';

export interface NavigationItemProps {
  link: string;
  label: string | ReactNode;
  active?: boolean;
  isIcon?: boolean;
}

const NavLink = styled(Link)({
  ':active': {
    color: colors.text300,
  },
  ':visited': {
    color: colors.text300,
  },
});

const NavItemContainer = styled(Box)<{ active: boolean; isIcon?: boolean }>(
  ({ active, isIcon }) => ({
    padding: '30px 18px 19px 18px',
    lineHeight: '43px',
    margin: '0 8px',
    boxSizing: 'content-box',
    borderBottom: 'solid 3px transparent',
    ...(active && {
      fontWeight: 'bold',
      borderBottom: 'solid 3px ' + colors.text400,
    }),
    ...(isIcon && {
      paddingBottom: 26,
      paddingTop: 37,
    }),
  })
);

export const NavigationItem: FC<NavigationItemProps> = ({
  link,
  label,
  active = false,
  isIcon,
}) => {
  const { pathname } = useLocation();

  return (
    <NavItemContainer
      active={_.startsWith(pathname, link) || active}
      isIcon={isIcon}
    >
      <NavLink to={link}>{label}</NavLink>
    </NavItemContainer>
  );
};
