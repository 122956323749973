import { Button, Input, Stack } from '@alexpireddu/map-ui';
import styled from '@emotion/styled';
import { FC } from 'react';
import { CitySiteNewsItem } from '../../apis/newsApis';
import { colors } from '../../theming/theme';
import _ from 'lodash';

export interface EmergencyRowProps {
  item?: CitySiteNewsItem;
  onEdit: () => void;
  disabled: boolean;
}

const FloatingContainer = styled.div({
  position: 'relative',
  width: '100%',
});

const FloatingInput = styled(Input)({
  paddingRight: 50,
  backgroundColor: colors.grey200,
  color: colors.text100,
  textTransform: 'uppercase',
});

const FloatingStack = styled(Stack)({
  position: 'absolute',
  right: 2,
  top: 1,
  height: '100%',
});

const EmergencyRow: FC<EmergencyRowProps> = ({ item, onEdit, disabled }) => (
  <Stack spacing={1} direction="row">
    <FloatingContainer>
      <FloatingInput
        isFullWidth
        readOnly
        value={item?.text}
        size="md"
        placeholder="Inserisci qui una nuova emergenza"
        onClick={() => {
          if (_.isNil(item)) {
            onEdit();
          }
        }}
      />
      <FloatingStack spacing={1} direction="row">
        <Button
          variant="unstyled"
          size="sm"
          icon="pen"
          onClick={onEdit}
          disabled={disabled}
        />
      </FloatingStack>
    </FloatingContainer>
  </Stack>
);

export default EmergencyRow;
