import { Box, Button, Flex, Stack, Text } from '@alexpireddu/map-ui';
import { FC } from 'react';
import { useActions, useCityParam } from '../../hooks';
import styled from '@emotion/styled';
import { colors } from '../../theming/theme';
import { useNavigate } from 'react-router';
import { useModalProvider } from '../../components';
import StopEmergenciesModal, {
  StopEmergenciesModalProps,
} from './StopEmergenciesModal';
import { getCityEmergencies, resetCityEmergencies } from '../../actions';

const Container = styled.div({
  backgroundColor: colors.danger200,
  paddingLeft: 32,
  paddingRight: 32,
});

const DangerButton = styled(Button)({
  borderColor: colors.white,
  color: colors.white,
  backgroundColor: colors.danger200,
  paddingLeft: 48,
  paddingRight: 48,
});

export interface HeaderBannerProps {
  emergencies: boolean;
}

const HeaderBanner: FC<HeaderBannerProps> = ({ emergencies }) => {
  const { currentCityId } = useCityParam();
  const navigate = useNavigate();
  const { openModal, closeModal } = useModalProvider();
  const [resetEmergencies, getEmergencies] = useActions([
    resetCityEmergencies,
    getCityEmergencies,
  ]);

  if (!emergencies) {
    return null;
  }

  const handleClose = (stop: boolean) => {
    if (stop) {
      resetEmergencies(currentCityId!).then(() => {
        getEmergencies(currentCityId);
        closeModal({ identifier: 'StopEmergenciesModal' });
      });
    } else {
      closeModal({ identifier: 'StopEmergenciesModal' });
    }
  };

  const handleOpen = () => {
    openModal<StopEmergenciesModalProps>({
      component: StopEmergenciesModal,
      identifier: 'StopEmergenciesModal',
      additionalProps: {
        onConfirm: () => handleClose(true),
        onClose: () => handleClose(false),
      },
    });
  };

  return (
    <Container>
      <Flex alignItems={'center'} height={50}>
        <Box flexGrow={4}>&nbsp;</Box>
        <Box flexGrow={6} textAlign={'center'}>
          <Text as="h3" color={colors.white} marginBottom={'0!important'}>
            Messaggi di emergenza in corso
          </Text>
        </Box>
        <Box flexGrow={1}>
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <DangerButton
              size="xs"
              icon="pen"
              variant="secondary"
              onClick={() => navigate(`/emergenze/${currentCityId}/edit`)}
              tabIndex={-1}
            >
              Modifica
            </DangerButton>
            <DangerButton
              size="xs"
              icon="close"
              variant="secondary"
              onClick={handleOpen}
              tabIndex={-1}
            >
              Interrompi
            </DangerButton>
          </Stack>
        </Box>
      </Flex>
    </Container>
  );
};

export default HeaderBanner;
