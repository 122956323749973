import { SortType } from '@alexpireddu/map-ui/dist/components/Table/AdvancedTable';
import _ from 'lodash';
import { PaginationResult } from '../infrastructure/dataTypes';
import { get, post, remove } from '../infrastructure/webRequest';

export interface VideoTimeSlot {
  from_date: string;
  id: string;
  site_id: string;
  slot_time: number;
  to_date: string;
}

export interface CampaignCity {
  id: string;
  name: string;
}

export interface VideoCampaign {
  from_date: string;
  id: string;
  image: { id: string };
  name: string;
  reserved_at: string;
  to_date: string;
}

export interface VideoTableCampaignInfo {
  from_date: string;
  city: CampaignCity;
  id: string;
  image: {
    id: string;
  };
  name: string;
  price: number;
  purpose: number;
  reserved_at: string;
  slots: Array<VideoTimeSlot>;
  state: number;
  state_at: string;
  to_date: string;
  user: {
    email: string;
    id: string;
    name: string;
    surname: string;
  };
}

export type VideoCampaignListResponse =
  PaginationResult<VideoTableCampaignInfo>;

export const getCampaignsV1 = (
  cityId: string,
  pageNumber: number,
  pageSize: number,
  state: number,
  sortProperty?: string,
  sortOrder?: SortType
) => {
  const additionalParams: string[] = [];
  if (!_.isNil(sortProperty) && !_.isNil(sortOrder)) {
    additionalParams.push(`&sort_by=${sortProperty}`);
    additionalParams.push(`&sort_mode=${sortOrder === 'desc' ? 2 : 1}`);
  }
  if (!_.isNil(state)) {
    additionalParams.push(`&state=${state}`);
  }

  return get<VideoCampaignListResponse>(
    `/v1/cityadmin/city/${cityId}/video/campaigns?page=${pageNumber}&size=${pageSize}${_.join(
      additionalParams,
      ''
    )}`
  );
};

export const getInstitutionalCampaignsV1 = (
  cityId: string,
  pageNumber: number,
  pageSize: number,
  state: number,
  sortProperty?: string,
  sortOrder?: SortType
) => {
  const additionalParams: string[] = [];
  if (!_.isNil(sortProperty) && !_.isNil(sortOrder)) {
    additionalParams.push(`&sort_by=${sortProperty}`);
    additionalParams.push(`&sort_mode=${sortOrder === 'desc' ? 2 : 1}`);
  }
  if (!_.isNil(state)) {
    additionalParams.push(`&state=${state}`);
  }

  return get<VideoCampaignListResponse>(
    `/v1/cityadmin/city/${cityId}/video/institutionals?page=${pageNumber}&size=${pageSize}${_.join(
      additionalParams,
      ''
    )}`
  );
};

export interface CampaignStateCount {
  count: number;
  state: number;
}

export const getCampaignsCountV1 = (cityId: string) =>
  get<Array<CampaignStateCount>>(
    `/v1/cityadmin/city/${cityId}/video/campaigns/state/count`
  );

export const getInstitutionalCampaignsCountV1 = (cityId: string) =>
  get<Array<CampaignStateCount>>(
    `/v1/cityadmin/city/${cityId}/video/institutionals/state/count`
  );

export type CityAdminVideoCampaigns = Array<VideoCampaign>;

export const getInstitutionalCampaignsApprovedV1 = (
  cityId: string,
  fromDate?: string,
  toDate?: string
) => {
  const additionalParams: string[] = [];
  if (!_.isNil(fromDate)) {
    additionalParams.push(`from_date=${fromDate}`);
  }
  if (!_.isNil(toDate)) {
    additionalParams.push(`to_date=${toDate}`);
  }

  const anyParam = !_.isNil(fromDate) || !_.isNil(toDate);

  return get<CityAdminVideoCampaigns>(
    `/v1/cityadmin/city/${cityId}/video/institutionals/approved${
      anyParam ? '?' : ''
    }${_.join(additionalParams, '&')}`
  );
};

export const createInstitutionalCampaignV1 = (
  cityId: string,
  data: FormData
) => {
  return post(`/v1/cityadmin/city/${cityId}/video/institutionals`, data);
};

export const stopInstitutionalCampaignV1 = (
  cityId: string,
  campaignId: string
) => remove(`/v1/cityadmin/city/${cityId}/video/institutionals/${campaignId}`);
