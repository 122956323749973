import { Stack, Text } from '@alexpireddu/map-ui';
import styled from '@emotion/styled';
import _ from 'lodash';
import { FC } from 'react';
import { VideoCampaign, CityAdminVideoCampaigns } from '../../apis/videoApi';
import Thumbnail from './Thumbnail';
import { useCityParam } from '../../hooks';
import {
  evaluateDurationInDays,
  formatDateLocale,
} from '../../utils/dateHelper';

const Paper = styled('div')({
  borderRadius: 12,
  padding: 12,
  border: '1px solid #cfd8dc',
  backgroundColor: 'rgba(236, 239, 241, 0.5)',
});

export interface NextCampaignDetailProps {
  campaign: VideoCampaign;
}

const NextCampaignDetail: FC<NextCampaignDetailProps> = ({ campaign }) => {
  const { currentCityId } = useCityParam();
  const remaining = evaluateDurationInDays(
    campaign?.from_date,
    campaign?.to_date
  );

  return (
    <Stack spacing={1} direction="row" alignItems={'center'}>
      <Thumbnail
        size="small"
        thumb={campaign.image.id}
        cityId={currentCityId!}
        campaignName={campaign.name}
      />
      <Text as="h4" marginLeft={8}>
        Nome comunicazione:
      </Text>
      <Text>{campaign.name}</Text>
      <Text as="h4" marginLeft={8}>
        Periodo:
      </Text>
      <Text>
        {formatDateLocale(campaign.from_date)} -{' '}
        {formatDateLocale(campaign.to_date)} ({remaining} giorni)
      </Text>
    </Stack>
  );
};

export interface NextCampaignsProps {
  nextCampaigns: CityAdminVideoCampaigns;
}

const NextCampaigns: FC<NextCampaignsProps> = ({ nextCampaigns }) => {
  if (!_.some(nextCampaigns)) {
    return null;
  }

  return (
    <Stack as={Paper} direction="column" spacing={1}>
      <Text as="h3">Ultime pianificazioni</Text>
      {_.map(
        _.take(
          _.orderBy(nextCampaigns, (x) => x.from_date, 'asc'),
          3
        ),
        (c) => (
          <NextCampaignDetail campaign={c} key={c.id} />
        )
      )}
    </Stack>
  );
};

export default NextCampaigns;
