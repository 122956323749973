import _ from 'lodash';
import { FC, useCallback, useEffect } from 'react';
import {
  getSubscriberInfo,
  getSubscriberRoles,
  refreshSubscriberToken,
} from '../../actions';
import { useAppSelector } from '../../hooks';
import { useActions } from '../../hooks/useActions';
import { ENV_VARIABLES } from '../../infrastructure/environment';

let timer: NodeJS.Timer;

const Bootstrapper: FC = ({ children }) => {
  const { user } = useAppSelector((store) => ({
    user: store.user,
  }));

  const [getUser, refreshToken, getRoles] = useActions([
    getSubscriberInfo,
    refreshSubscriberToken,
    getSubscriberRoles,
  ]);

  const runRefreshTokenTimer = useCallback(() => {
    const interval = setInterval(
      () => refreshToken(),
      ENV_VARIABLES.REFRESH_TOKEN_TIMER_TIMESPAN
    );

    return interval;
  }, [refreshToken]);

  useEffect(() => {
    const { fetched, fetching, error } = user;

    if (!fetched && !fetching && _.isNil(error)) {
      getUser().then(() => {
        timer = runRefreshTokenTimer();
      });
    }
  }, [user, getUser, runRefreshTokenTimer]);

  useEffect(() => {
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    const { data, error } = user;

    if (_.isNil(error) && !_.some(data.roles)) {
      getRoles();
    }
  }, [user, getRoles]);

  const { fetching: userFetching, fetched: userFetched } = user;

  return <>{!userFetching && userFetched && children}</>;
};

export default Bootstrapper;
