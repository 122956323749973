import styled from '@emotion/styled';
import { colors } from '../../theming/theme';

const IconSvg = styled.svg({
  color: colors.text300,
  fill: colors.text300,
  height: '24px',
  width: '24px',
});

export default IconSvg;
