import {
  configureSentryUser,
  resetSentryUser,
} from '../infrastructure/sentryConfiguration';
import { get, post } from '../infrastructure/webRequest';

export interface UpdateSubscriberRequest {
  email: string;
  name: string;
  new_password: string;
  new_password_confirm: string;
  old_password: string;
}

export type UserRole = string;

export type UserRoles = Array<UserRole>;

export interface UserInfo {
  email: string;
  id: string | null;
  name: string;
  phone?: string;
  surname?: string;
  sso_user: boolean;
  roles: UserRoles;
}

export const getSubscriberRolesV1 = () =>
  get<UserRoles>('/v1/subscriber/roles');

export const updateSubscriberV1 = (subscriber: UpdateSubscriberRequest) =>
  post('/v1/subscriber/update', subscriber);

export const getSubscriberInfoV1 = () =>
  get<UserInfo>('/v1/subscriber/info').then((resp) => {
    const {
      data: { id, email, name, surname },
    } = resp;
    configureSentryUser(id!, email, name, surname);

    return resp;
  });

export const refreshTokenV1 = () => {
  get('/v1/subscriber/token/refresh');
};

export const subscriberLogoutV1 = () =>
  get(`/v1/subscriber/logout`).then((resp) => {
    resetSentryUser();

    return resp;
  });
