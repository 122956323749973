import _ from 'lodash';

export const getLastCurrentCityId = () => {
  if (!_.isNil(window.localStorage)) {
    const currentCityId = window.localStorage.getItem('current_city');
    if (!_.isNil(currentCityId)) {
      return currentCityId;
    }
  }

  return null;
};

export const setLastCurrentCityId = (cityId: string | undefined) => {
  if (!_.isNil(window.localStorage)) {
    if (!_.isNil(cityId)) {
      window.localStorage.setItem('current_city', cityId);
    } else {
      window.localStorage.removeItem('current_city');
    }
  }
};
