import { Alert, Box, Flex, Text } from '@alexpireddu/map-ui';
import styled from '@emotion/styled';
import { FC } from 'react';
import { shadows } from '../../theming/theme';

const AlertStyled = styled(Alert)({
  boxShadow: shadows.base,
});

const NoSitesAlert: FC = () => (
  <AlertStyled variant="warning" size="xl">
    <Flex alignItems="center">
      <Box flexGrow={4}>
        <Text>
          Al momento non sono stati configurati gli impianti di questo comune.
        </Text>
        <Text>
          Per rendere funzionante il servizio Publista, aggiungi gli impianti
          disponibili nel tuo comune.
        </Text>
      </Box>
    </Flex>
  </AlertStyled>
);

export default NoSitesAlert;
