export const CampaignState = {
  Approved: 210,
  InProgress: 220,
  Completed: 230,
};

export const formatCampaignState = (stato: number) => {
  switch (stato) {
    case CampaignState.Approved:
      return 'Approvata';
    case CampaignState.InProgress:
      return 'In corso';
    case CampaignState.Completed:
      return 'Conclusa';
    default:
      return 'Sconosciuto';
  }
};
