import { Tooltip } from '@alexpireddu/map-ui';
import styled from '@emotion/styled';
import { FC, useMemo } from 'react';
import { colors } from '../../theming/theme';
import _ from 'lodash';

export interface TimerProps {
  expiration: number;
}

const TimerText = styled.div({
  lineHeight: '2.5rem',
  display: 'inline-block',
  fontWeight: 700,
  color: colors.interaction200,
  paddingLeft: 5,
});

const Timer: FC<TimerProps> = ({ expiration }) => {
  const gg = useMemo(
    () => (expiration === 1 ? 'giorno rimanente' : 'giorni rimanenti'),
    [expiration]
  );

  if (_.isNil(expiration) || expiration <= 0) {
    return null;
  }

  return (
    <span>
      <Tooltip
        content={
          <span>
            {expiration} {gg}, poi
            <br />
            la news verrà eliminata.
          </span>
        }
      >
        <TimerText>-{expiration}</TimerText>
      </Tooltip>
    </span>
  );
};

export default Timer;
