import { FC } from 'react';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { colors } from '../../theming/theme';
import { Stack } from '@alexpireddu/map-ui';

export interface EmergenciesPreviewProps {
  list: string[];
  charsPerSecond?: number;
}

const backgroundAnimation = keyframes`
    0%, 49% { background-color: ${colors.danger200}; color: ${colors.white}; }
    50%, 100% { background-color: ${colors.white}; color: ${colors.danger200}; }
`;

const Root = styled.div({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  color: colors.white,
  backgroundColor: colors.danger200,
  animation: `${backgroundAnimation} linear 1.5s infinite`,
});

const PanelTitle = styled.div({
  color: colors.white,
  backgroundColor: colors.text300,
  textAlign: 'center',
  fontSize: '2em',
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
  fontWeight: 800,
  padding: 10,
});

const Item = styled.div({
  flexShrink: 0,
  boxSizing: 'border-box',
  padding: 10,
  textAlign: 'center',
  fontSize: '2em',
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
  fontWeight: 800,
});

const tickerh = keyframes`
    0% { transform: translateX(100vw); }
    100% { transform: translateX(-100%); }
`;

const Container = styled.div({
  overflow: 'hidden',
  width: '100%',
  borderTop: `solid 2px ${colors.danger200}`,
});

const Scroller = styled.div<{ charsPerSecond: number; charsCount: number }>(
  ({ charsPerSecond, charsCount }) => {
    const dur = Math.round(charsCount / charsPerSecond);
    const minDr = 4;

    return {
      display: 'flex',
      animation: `${tickerh} linear ${dur > minDr ? dur : minDr}s infinite`,
      width: 'fit-content',
    };
  }
);

const EmergenciesPreview: FC<EmergenciesPreviewProps> = ({
  list: news,
  charsPerSecond = 3,
}) => {
  if (news.length === 0) {
    return null;
  }

  const items = news.flatMap((el, i) =>
    i !== news.length - 1
      ? [<Item key={i}>{el}</Item>, <Item key={`${i}-`}>-</Item>]
      : [<Item key={i}>{el}</Item>]
  );
  const charsCount = news.reduce((acc, n) => acc + n.length, 0) + news.length;

  return (
    <Root>
      <Stack direction="row">
        <PanelTitle>Emergenze</PanelTitle>
        <Container>
          <Scroller charsPerSecond={charsPerSecond} charsCount={charsCount}>
            {items}
          </Scroller>
        </Container>
      </Stack>
    </Root>
  );
};

export default EmergenciesPreview;
