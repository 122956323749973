import { Avatar, Box, Card, Flex } from '@alexpireddu/map-ui';
import styled from '@emotion/styled';
import { FC, useMemo } from 'react';
import { CityUserInfo } from '../../../apis/cityApis';

export type EmployeeRecordProps = {
  employee: CityUserInfo;
};

const RecordContainer = styled(Card)({
  flexGrow: 10,
  marginBottom: '16px',
});

const UserName = styled.p({
  fontSize: '1rem',
  fontWeight: '700',
  lineHeight: '1.3rem',
});

const UserEmail = styled.p({
  fontSize: '14px',
});

const EmployeeRecord: FC<EmployeeRecordProps> = ({ employee }) => {
  const fullName = useMemo(
    () => `${employee.user.name} ${employee.user.surname}`,
    [employee]
  );

  return (
    <RecordContainer>
      <Flex alignItems={'center'}>
        <Box mr="16px" ml="24px">
          <Avatar name={fullName} className="avatar" variant="light" />
        </Box>
        <Box flexGrow={2}>
          <UserName>{fullName}</UserName>
          <UserEmail>{employee.user.email}</UserEmail>
        </Box>
      </Flex>
    </RecordContainer>
  );
};

export default EmployeeRecord;
