import { Card, Stack, Text } from '@alexpireddu/map-ui';
import _ from 'lodash';
import { FC } from 'react';
import { CitySiteNews } from '../../apis/newsApis';
import NewsRow from './NewsRow';
import { useVideoService } from '../../hooks';

export interface NewsListProps {
  news: CitySiteNews;
  onEdit: () => void;
}

const NewsList: FC<NewsListProps> = ({ news, onEdit }) => {
  const videoService = useVideoService();

  return (
    <Card size="lg">
      <Stack spacing={2}>
        <Text as="h3">Programmazione</Text>
        {_.map(news, (n) => (
          <NewsRow
            key={n.id}
            newsItem={n}
            onEdit={onEdit}
            disabled={!videoService}
          />
        ))}
        {!_.some(news) && <NewsRow onEdit={onEdit} disabled={!videoService} />}
      </Stack>
    </Card>
  );
};

export default NewsList;
