import { Alert, Box, Flex, Text } from '@alexpireddu/map-ui';
import styled from '@emotion/styled';
import { FC } from 'react';
import { shadows } from '../../theming/theme';

const AlertStyled = styled(Alert)({
  boxShadow: shadows.base,
});

const NoVideoServiceAlert: FC = () => (
  <AlertStyled variant="warning" size="xl">
    <Flex alignItems="center">
      <Box flexGrow={4}>
        <Text>Il servizio non risulta attivo per questo comune.</Text>
      </Box>
    </Flex>
  </AlertStyled>
);

export default NoVideoServiceAlert;
