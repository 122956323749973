import { Box, Flex } from '@alexpireddu/map-ui';
import styled from '@emotion/styled';
import { FC, ReactNode } from 'react';
import { colors } from '../../theming/theme';

export interface HeaderProps {
  leftContent?: ReactNode;
  middleContent?: ReactNode;
  rightContent?: ReactNode;
}

const HeaderContainer = styled(Flex)({
  alignItems: 'center',
  backgroundColor: colors.white,
  borderBottom: 'solid 1px ' + colors.grey300,
  padding: '0 42px',
  minHeight: '94px',
});

const Header: FC<HeaderProps> = ({
  leftContent,
  rightContent,
  middleContent,
  ...rest
}) => (
  <HeaderContainer {...rest}>
    <Box className="pa__header_left">
      <Flex alignItems="center">{leftContent}</Flex>
    </Box>
    <Box className="pa__header_middle" flexShrink={3} flexGrow={4}>
      <Flex alignItems="center">{middleContent}</Flex>
    </Box>
    <Box className="pa__header_right">
      <Flex alignItems="center">{rightContent}</Flex>
    </Box>
  </HeaderContainer>
);

export default Header;
