import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { CityRoutingParam } from '../infrastructure/commonTypes';
import { useAppSelector } from './storeHooks';

export const useCurrentCity = () => {
  const { currentCityId } = useParams<Partial<CityRoutingParam>>();

  return useAppSelector((store) =>
    _.find(store.cities.data, (x) => x.id === currentCityId)
  );
};
