import { Stack, Text } from '@alexpireddu/map-ui';
import { FC, useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { DefaultApplication, useModalProvider } from '../../components';
import { useAction, useAppSelector, useCityParam } from '../../hooks';
import ManageEmergenciesModal, {
  ManageEmergenciesModalProps,
} from './ManageEmergenciesModal';
import EmergenciesList from './EmergenciesList';
import { getCityEmergencies } from '../../actions';
import _ from 'lodash';
import EmergenciesPreview from './EmergenciesPreview';
import withCityRouting from '../../infrastructure/withCityRouting';

export const NEWS_MAX_LENGTH = 150;
export const NEWS_LIST_MAX_SIZE = 5;

export interface EmergenciesPageProps {
  editMode?: boolean;
}

const EmergenciesPage: FC<EmergenciesPageProps> = ({ editMode }) => {
  const { currentCityId } = useCityParam();
  const { openModal, closeModal } = useModalProvider();
  const { emergencies } = useAppSelector((store) => ({
    emergencies: _.map(store.emergencies.data, (n) => ({
      ...n,
    })),
  }));
  const getEmergencies = useAction(getCityEmergencies);

  const loadEmergencies = useCallback(() => {
    return getEmergencies(currentCityId);
  }, [currentCityId, getEmergencies]);

  const handleClose = useCallback(
    (reload: boolean) => {
      closeModal({
        identifier: 'ManageEmergenciesModal',
      });

      if (reload) {
        loadEmergencies();
      }
    },
    [closeModal, loadEmergencies]
  );

  const handleOpen = useCallback(() => {
    openModal<ManageEmergenciesModalProps>({
      component: ManageEmergenciesModal,
      identifier: 'ManageEmergenciesModal',
      additionalProps: {
        emergencies: emergencies,
        onClose: handleClose,
        cityId: currentCityId!,
      },
    });
  }, [currentCityId, handleClose, openModal, emergencies]);

  useEffect(() => {
    loadEmergencies().then(() => {
      if (editMode) {
        handleOpen();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DefaultApplication>
      <Helmet title="News" />

      <div style={{ maxWidth: '1080px', margin: '0 auto' }}>
        <Stack marginTop={24} spacing={4}>
          <Text as="h2">Emergenze</Text>
          <EmergenciesList emergencies={emergencies} onEdit={handleOpen} />
        </Stack>
      </div>

      <EmergenciesPreview list={_.map(emergencies, (n) => n.text)} />
    </DefaultApplication>
  );
};

export default withCityRouting(EmergenciesPage);
