import { get, put } from '../infrastructure/webRequest';

export type UpdateCityNewsItem = {
  id: string;
  site_ids: Array<string>;
  text: string;
};

export type UpdateCityNewsRequest = {
  cityId: string;
  newsList: Array<UpdateCityNewsItem>;
};

export const updateCityNewsV1 = (request: UpdateCityNewsRequest) =>
  put<UpdateCityNewsRequest>(
    `/v1/cityadmin/city/${request.cityId}/video/news`,
    {
      emergency: false,
      news: request.newsList,
    }
  );

export const updateCityEmergenciesV1 = (request: UpdateCityNewsRequest) =>
  put<UpdateCityNewsRequest>(
    `/v1/cityadmin/city/${request.cityId}/video/news`,
    {
      emergency: true,
      news: request.newsList,
    }
  );

export const resetCityEmergenciesV1 = (cityId: string) =>
  put<UpdateCityNewsRequest>(`/v1/cityadmin/city/${cityId}/video/news`, {
    emergency: true,
    news: [],
  });

export type CitySiteNewsItemUser = {
  email: string;
  id: string;
  name: string;
  surname: string;
};

export type CitySiteNewsItem = UpdateCityNewsItem & {
  city_id: string;
  created_at: string;
  user?: CitySiteNewsItemUser;
  expiration?: number;
};

export type CitySiteNews = Array<CitySiteNewsItem>;

export const getCityNewsV1 = (cityId: string) =>
  get<CitySiteNews>(`/v1/cityadmin/city/${cityId}/video/news?emergency=false`);

export const getCityEmergenciesV1 = (cityId: string) =>
  get<CitySiteNews>(`/v1/cityadmin/city/${cityId}/video/news?emergency=true`);
